import React from "react";
import { Route, Routes } from 'react-router-dom';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./assets/scss/style.scss"
import "./assets/css/materialdesignicons.min.css"
import '../src/index.css'; // Assuming your file is named tailwind.css

import Index from "./pages";
import IndexTwo from "./pages/index-two";
import IndexThree from "./pages/index-three";
import JobCategories from "./pages/job-categories";
import JobGridOne from "./pages/job-grid-one";
import JobGridTwo from "./pages/job-grid-two";
import JobGridThree from "./pages/job-grid-three";
import JobGridFour from "./pages/job-grid-four";
import JobListOne from "./pages/job-list-one";
import JobListTwo from "./pages/job-list-two";
import JobApply from "./pages/job-apply";
import JobPost from "./pages/job-post";
import Career from "./pages/career";

import JobDetailThree from "./pages/job-detail-three";
import JobDetailTwo from "./pages/job-detail-two";
import JobDetailOne from "./pages/job-detail-one";
import Employers from "./pages/employers";
import EmployerProfile from "./pages/employer-profile";
import Candidates from "./pages/candidates";
import CandidateProfile from "./pages/candidate-profile";
import CandidateProfileSetting from "./pages/candidate-profile-setting";
import AboutUs from "./pages/aboutus";
import Services from "./pages/services";
import Pricing from "./pages/pricing";
import HelpcenterOverview from "./pages/helpcenter-overview";
import HelpcenterFaq from "./pages/helpcenter-faqs";
import HelpcenterGuides from "./pages/helpcenter-guides";
import HelpcenterSupport from "./pages/helpcenter-support";
import Blogs from "./pages/blogs";
import BlogSidebar from "./pages/blog-sidebar";
import BlogDetail from "./pages/blog-detail";
import ClientLoginScreen from "./pages/clientlogin";
import Signup from "./pages/clientsignup";

import ResetPassword from "./pages/reset-password";
import Applications from "./pages/applicants";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import ContactUs from "./pages/contactus";
import Error from "./pages/error";
import Comingsoom from "./pages/comingsoon";
import Maintenance from "./pages/maintenance";
import ExpertSignup from "./pages/expertsignup";
import RegistrationPage from "./pages/register";
import ExpertDashboard from "./pages/expertdashboard";
import ExpertsSlider from "./pages/expertsslider";
import ClientSlider from "./pages/clientsidebar";
import MultiPageForm from "./pages/roleform";
import RoleForm from "./pages/roleform";
import CompanySizeForm from "./pages/companysizeform";
import BookACall from "./pages/bookacall";
import EditProfile from "./pages/editprofile";
import NavDash from "./pages/navdash";
import AccountSettings from "./pages/accountsettings";
import Sidebar from "./pages/expertsidebar";
import ClientDashboard from "./pages/clientdashboard";

import CustomNavbar from "./pages/customnavbar";
import ClientSidebar from "./pages/clientsidebar";
import ClientNavbar from "./pages/clientnavbar";
import AllJobs from "./pages/alljobs";
import EditJob from "./pages/editjob";
import ExpertNavbar from "./pages/expertnavbar";
import MyJobs from "./pages/myjobs";
import SavedJobs from "./pages/savedjobs";
import EditJobPopup from "./pages/editedjobpopup";
import MessagingComponent from "./components/messaging";
import ExpertLogin from "./pages/expertlogin";
import ClientRegistrationPage from "./pages/clientregister";
import ForgotPasswordPage from "./pages/clientreset";
import ViewJob from "./pages/viewjob";

function App() {
  return (
   <>
   <Routes>
      <Route path='/' exact element={<IndexTwo/>}/>
      <Route path='/index' element={<IndexTwo/>}/>
      <Route path="/candidate-profile/:email" element={<CandidateProfile />} />
      <Route path='/index-two' element={<IndexTwo/>}/>
      <Route path='/index-three' element={<IndexThree/>}/>
      <Route path='/expertsignup' element={<ExpertSignup/>}/>
      <Route path='/expertlogin' element={<ExpertLogin/>}/>
      <Route path='/clientreset' element={<ForgotPasswordPage/>}/>


      <Route path='/job-categories' element={<JobCategories/>}/>
      <Route path='/job-grid-one' element={<JobGridOne/>}/>
      <Route path='/multipage' element={<MultiPageForm/>}/>
      <Route path='/job-grid-two' element={<JobGridTwo/>}/>
      <Route path='/job-grid-three' element={<JobGridThree/>}/>
      <Route path='/job-grid-four' element={<JobGridFour/>}/>
      <Route path='/job-list-one' element={<JobListOne/>}/>
      <Route path='/job-list-Two' element={<JobListTwo/>}/>
      <Route path='/job-apply' element={<JobApply/>}/>
      <Route path='/job-post' element={<JobPost/>}/>
      <Route path='/career' element={<Career/>}/>
      <Route path='/clientsidebar' element={<ClientSidebar/>}/>
      <Route path='/customnavbar' element={<CustomNavbar/>}/>
      <Route path='/expertnavbar' element={<ExpertNavbar/>}/>
      <Route path='/register' element={<RegistrationPage/>}/>
      <Route path='/job-detail-three' element={<JobDetailThree/>}/>
      <Route path='/job-detail-three/:id' element={<JobDetailThree/>}/>
      <Route path='/job-detail-two' element={<JobDetailTwo/>}/>
      <Route path='/job-detail-two/:id' element={<JobDetailTwo/>}/>
      <Route path='/job-detail-one' element={<JobDetailOne/>}/>
      <Route path='/job-detail-one/:id' element={<JobDetailOne/>}/>
      <Route path='/employers' element={<Employers/>}/>
      
      <Route path='/viewjob' element={<ViewJob/>}/>
    
      <Route path='/employer-profile' element={<EmployerProfile/>}/>
      <Route path='/employer-profile/:id' element={<EmployerProfile/>}/>
      <Route path='/candidates' element={<Candidates/>}/>
      <Route path='/navdash' element={<NavDash/>}/>
      <Route path='/clientregister' element={<ClientRegistrationPage/>}/>
      <Route path='/candidate-profile' element={<CandidateProfile/>}/>
      <Route path='/candidate-profile/:id' element={<CandidateProfile/>}/>
      <Route path='/candidate-profile-setting' element={<CandidateProfileSetting/>}/>
      <Route path='/aboutus' element={<AboutUs/>}/>
      <Route path='/services' element={<Services/>}/>
      <Route path='/pricing' element={<Pricing/>}/>
      <Route path='/expertsslider' element={<ExpertsSlider/>}/>
      <Route path='/expertdashboard' element={<ExpertDashboard/>}/>
      <Route path='/helpcenter-overview' element={<HelpcenterOverview/>}/>
      <Route path='/helpcenter-faqs' element={<HelpcenterFaq/>}/>
      <Route path='/helpcenter-guides' element={<HelpcenterGuides/>}/>
      <Route path='/helpcenter-support' element={<HelpcenterSupport/>}/>
      <Route path='/blogs' element={<Blogs/>}/>
      <Route path='/myjobs' element={<MyJobs/>}/>
      <Route path='/blog-sidebar' element={<BlogSidebar/>}/>
      <Route path='/blog-detail' element={<BlogDetail/>}/>
      <Route path='/blog-detail/:id' element={<BlogDetail/>}/>
      <Route path='/clientlogin' element={<ClientLoginScreen/>}/>
      <Route path='/clientsignup' element={<Signup/>}/>
      <Route path='/reset-password' element={<ResetPassword/>}/>
      <Route path='/applicants' element={<Applications/>}/>
      <Route path='/terms' element={<Terms/>}/>
      <Route path='/accountsettings' element={<AccountSettings/>}/>
      <Route path='/privacy' element={<Privacy/>}/>
      <Route path='/contactus' element={<ContactUs/>}/>
      <Route path='*' element={<Error/>}/>
      <Route path='/messagingcomponent' element={<MessagingComponent/>}/>
      <Route path='expertsidebar' element={<Sidebar/>}/>
      <Route path='/error' element={<Error/>}/>
      <Route path='/editedjobpopup' element={<EditJobPopup/>}/>
      <Route path='/clientdashboard' element={<ClientDashboard/>}/>
      <Route path='/comingsoon' element={<Comingsoom/>}/>
      <Route path='/maintenance' element={<Maintenance/>}/>
      <Route path='/roleform' element={<RoleForm/>}/>
      <Route path='/companysizeform' element={<CompanySizeForm/>}/>
      <Route path='/bookacall' element={<BookACall/>}/>
      <Route path='/expertdashboard' element={<ExpertDashboard/>}/>
      <Route path='/editprofile' element={<EditProfile/>}/>
      <Route path='/clientnavbar' element={<ClientNavbar/>}/>
      <Route path='/alljobs' element={<AllJobs/>}/>
      <Route path='/editjob' element={<EditJob/>}/>
      <Route path='/savedjobs' element={<SavedJobs/>}/>
   </Routes>
   
   </>
   
  );
}

export default App;
