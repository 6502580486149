import React, { useState, useEffect } from 'react';
import { getAuth, reauthenticateWithCredential, updatePassword, EmailAuthProvider } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

function AccountSettings() {
  const auth = getAuth();
  const db = getFirestore();
  const user = auth.currentUser;

  const [profile, setProfile] = useState({
    email: '',
    profileImage: '',
    title: ''
  });
  const [passwordInput, setPasswordInput] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  useEffect(() => {
    if (!user) {
      setMessageType('error');
      setMessage('No user logged in.');
      return;
    }

    const fetchUserProfile = async () => {
      const collections = ['profiles', 'members', 'users']; // Example collections to search for the profile
      let profileFound = false;

      for (const collectionName of collections) {
        if (profileFound) break; // If a profile is found, break out of the loop

        const colRef = collection(db, collectionName);
        const q = query(colRef, where("email", "==", user.email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            if (doc.exists) {
              setProfile({
                email: doc.data().email,
                profileImage: doc.data().profileImage || 'https://via.placeholder.com/100',
                title: doc.data().title || 'Not provided'
              });
              profileFound = true;
            }
          });
        }
      }

      if (!profileFound) {
        setMessageType('error');
        setMessage('Profile not found in any collections.');
      }
    };

    fetchUserProfile();
  }, [user, db]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordInput(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (passwordInput.newPassword !== passwordInput.confirmNewPassword) {
      setMessageType('error');
      setMessage('New passwords do not match.');
      return;
    }
    const credential = EmailAuthProvider.credential(user.email, passwordInput.currentPassword);
    try {
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, passwordInput.newPassword);
      setMessageType('success');
      setMessage('Password updated successfully!');
      // Optionally log this event in Firestore or another service
    } catch (error) {
      setMessageType('error');
      setMessage(error.message);
    }
  };

  const handleDeleteProfile = async () => {
    if (window.confirm('Are you sure you want to delete your profile? This cannot be undone.')) {
      // Placeholder for actual deletion logic
      setMessageType('success');
      setMessage('Profile deleted successfully!');
      // Optionally log this deletion event
    }
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '600px',
      margin: '40px auto',
      padding: '20px',
      fontFamily: "'Helvetica Neue', Arial, sans-serif",
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
    },
    header: {
      marginBottom: '20px',
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#333',
    },
    formGroup: {
      marginBottom: '20px',
    },
    label: {
      display: 'block',
      marginBottom: '10px',
      fontSize: '16px',
      color: '#333',
    },
    input: {
      width: '100%',
      padding: '10px',
      fontSize: '14px',
      border: '1px solid #ced4da',
      borderRadius: '4px',
      marginBottom: '10px',
    },
    button: {
      padding: '10px 15px',
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#fff',
      backgroundColor: '#007bff',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      width: '100%',
    },
    profileInfo: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
    },
    profileImage: {
      width: '100px',
      height: '100px',
      borderRadius: '50%',
      marginRight: '20px',
      objectFit: 'cover',
    },
    profileDetails: {
      lineHeight: '1.5',
    },
    messageBox: {
      padding: '10px',
      margin: '10px 0',
      borderRadius: '4px',
      color: '#fff',
      backgroundColor: messageType === 'error' ? '#dc3545' : '#28a745',
    }
  };

  return (
    <div style={styles.container}>
      {message && <div style={styles.messageBox}>{message}</div>}
      <div style={styles.profileInfo}>
        <img src={profile.profileImage} alt="Profile" style={styles.profileImage} />
        <div style={styles.profileDetails}>
          <div><strong>Name:</strong> {profile.name || 'Not provided'}</div>
          <div><strong>Email:</strong> {profile.email}</div>
          <div><strong>Title:</strong> {profile.title}</div>
        </div>
      </div>
      <div style={styles.header}>Account Settings</div>
      <form onSubmit={handleUpdatePassword}>
        <div style={styles.formGroup}>
          <label htmlFor="currentPassword" style={styles.label}>Current Password *</label>
          <input
            type="password"
            id="currentPassword"
            name="currentPassword"
            value={passwordInput.currentPassword}
            onChange={handleInputChange}
            required
            style={styles.input}
          />
          <label htmlFor="newPassword" style={styles.label}>New Password *</label>
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            value={passwordInput.newPassword}
            onChange={handleInputChange}
            required
            style={styles.input}
          />
          <label htmlFor="confirmNewPassword" style={styles.label}>Confirm New Password *</label>
          <input
            type="password"
            id="confirmNewPassword"
            name="confirmNewPassword"
            value={passwordInput.confirmNewPassword}
            onChange={handleInputChange}
            required
            style={styles.input}
          />
          <button style={styles.button} type="submit">Update Password</button>
        </div>
      </form>
      <button onClick={handleDeleteProfile} style={{ ...styles.button, backgroundColor: '#dc3545' }}>
        Delete My Profile
      </button>
    </div>
  );
}

export default AccountSettings;
