import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut as firebaseSignOut } from 'firebase/auth';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBell, faCommentDots, faUserCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { auth, db } from '../firebase';
import logoDark from '../assets/images/logo-dark.png';

function ClientNavbar() {
  const [user, setUser] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsDropdownVisible, setNotificationsDropdownVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setUser({
          uid: firebaseUser.uid,
          displayName: firebaseUser.displayName ? firebaseUser.displayName.split(' ')[0] : 'User',
          photoURL: firebaseUser.photoURL,
          email: firebaseUser.email,
        });
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let unsubscribeNotifications = () => {};
    if (user && user.uid) {
      const notificationsQuery = query(collection(db, 'notifications'), where('clientId', '==', user.uid));
      unsubscribeNotifications = onSnapshot(notificationsQuery, (snapshot) => {
        const fetchedNotifications = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setNotifications(fetchedNotifications);
      });
    }
    return () => unsubscribeNotifications();
  }, [user]);

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
      navigate('/clientlogin');
    } catch (error) {
      console.error('Sign Out Error:', error);
    }
  };

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);
  const toggleNotificationsDropdown = () => setNotificationsDropdownVisible(!notificationsDropdownVisible);

  return (
    <nav style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#0a58ca',
      color: 'white',
      padding: '0.5rem 2rem',
      marginBottom: '20px',
    }}>
      <div style={{ height: '50px' }}>
        <img src={logoDark} alt="Logo" style={{ borderRadius: '50%', height: '100%' }} />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FontAwesomeIcon icon={faSearch} style={{ color: 'white', margin: '0 10px' }} />
        <div style={{ position: 'relative' }}>
          <FontAwesomeIcon icon={faBell} style={{ color: 'white', margin: '0 10px', cursor: 'pointer' }} onClick={toggleNotificationsDropdown} />
          {notifications.length > 0 && (
            <span style={{
              position: 'absolute',
              top: '-5px',
              right: '-5px',
              backgroundColor: 'red',
              color: 'white',
              borderRadius: '50%',
              padding: '2px 6px',
              fontSize: '12px',
            }}>
              {notifications.length}
            </span>
          )}
          {notificationsDropdownVisible && (
            <div style={{
              display: 'block',
              position: 'absolute',
              right: '2rem',
              top: '3rem',
              backgroundColor: 'white',
              boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
              zIndex: 100,
              borderRadius: '4px',
              width: '200px',
            }}>
              {notifications.map((notification, index) => (
                <div key={index} style={{
                  padding: '12px 16px',
                  borderBottom: '1px solid #f1f1f1',
                  color: 'black',
                }}>{notification.message}</div>
              ))}
            </div>
          )}
        </div>
        <FontAwesomeIcon icon={faCommentDots} style={{ color: 'white', margin: '0 10px' }} onClick={() => navigate('/clientdashboard')} />
        {user ? (
          <>
            <span style={{ color: 'white', marginRight: '10px' }}>Hello, {user.displayName}</span>
            <img
              src={user.photoURL || '/path-to-default-profile-image.png'}
              alt="Profile"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                cursor: 'pointer',
                objectFit: 'cover',
                marginLeft: '10px',
              }}
              onClick={toggleDropdown}
            />
            <FontAwesomeIcon icon={faSignOutAlt} style={{
              color: 'red',
              margin: '0 10px',
              fontSize: '20px',
              cursor: 'pointer'
            }} onClick={signOut} />
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faUserCircle} style={{ color: 'white', margin: '0 10px', fontSize: '16px', cursor: 'pointer' }} onClick={() => navigate('/login')} />
            <span style={{ color: 'white', cursor: 'pointer' }} onClick={() => navigate('/login')}>Login</span>
          </>
        )}
      </div>
    </nav>
  );
}

export default ClientNavbar;
