import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import ClientNavbar from './clientnavbar';
import profImage from '../assets/images/team/02.jpg';
import heroImage from '../assets/images/hero.jpg'; // Ensure this path is correct

const Applications = () => {
    const [applicants, setApplicants] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [applicantsPerPage, setApplicantsPerPage] = useState(5);
    const [activeTab, setActiveTab] = useState('all');
    const [totalJobs, setTotalJobs] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                await fetchApplicants(user.email);
                await fetchTotalJobs(user.email); // Fetch the total number of jobs for the logged-in user
            } else {
                navigate('/clientlogin');
            }
        });
        return () => unsubscribe();
    }, [auth, navigate]);
    const fetchApplicants = async (userEmail) => {
        const jobQuery = query(collection(db, 'jobs'), where('userEmail', '==', userEmail));
        const jobsSnapshot = await getDocs(jobQuery);
        const jobIds = jobsSnapshot.docs.map(doc => doc.id);

        setTotalJobs(jobsSnapshot.size);

        if (jobIds.length > 0) {
            const applicantsQuery = query(collection(db, 'jobApplications'), where('jobID', 'in', jobIds));
            const applicantsSnapshot = await getDocs(applicantsQuery);
            const applicantsData = applicantsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                jobTitle: jobsSnapshot.docs.find(jobDoc => jobDoc.id === doc.data().jobId)?.data().title || 'Unknown Job Title',
                profileImageUrl: doc.data().profileImageUrl || profImage,
            }));

            setApplicants(applicantsData);
        } else {
            setApplicants([]);
        }
    };

    const fetchTotalJobs = async (userEmail) => {
        const jobsQuery = query(collection(db, 'jobs'), where('userEmail', '==', userEmail));
        const jobsSnapshot = await getDocs(jobsQuery);
        setTotalJobs(jobsSnapshot.size); // Set the total number of jobs found
    };

    const handleShortlist = async (applicantId) => {
        const applicantRef = doc(db, 'jobApplications', applicantId);
        await updateDoc(applicantRef, { shortlisted: true });
        setApplicants(applicants.map(app => app.id === applicantId ? { ...app, shortlisted: true } : app));
    };

    const handleReject = async (applicantId) => {
        const applicantRef = doc(db, 'jobApplications', applicantId);
        await updateDoc(applicantRef, { rejected: true });
        setApplicants(applicants.map(app => app.id === applicantId ? { ...app, rejected: true } : app));
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setCurrentPage(1);
    };

    const filteredApplicants = applicants.filter(applicant => {
        const jobTypeMatch = applicant.jobType?.toLowerCase().includes(searchTerm.toLowerCase());
        const coverLetterMatch = applicant.coverLetter?.toLowerCase().includes(searchTerm.toLowerCase());
        const nameMatch = applicant.name?.toLowerCase().includes(searchTerm.toLowerCase());
        const jobTitleMatch = applicant.jobTitle?.toLowerCase().includes(searchTerm.toLowerCase());
        return jobTypeMatch || coverLetterMatch || nameMatch || jobTitleMatch;
    }).filter(applicant => activeTab === 'all' || applicant[activeTab]);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredApplicants.length / applicantsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <>
            <ClientNavbar />
            <div style={{ backgroundImage: `url(${heroImage})`, height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', color: 'white', textAlign: 'center' }}>
                <h1>Welcome to the Applicants Page</h1>
                <p>Here you can find all the talents ready to join and elevate your team.</p>
            </div>
            <div className="container mt-4" style={{ fontFamily: 'Arial, sans-serif' }}>
                <div className="row">
                    <div className="col-md-3" style={{ borderRight: '2px solid #ddd' }}>
                       
                        <button onClick={() => navigate('/alljobs')} style={sidebarButtonStyle}>
                            View All Jobs <span style={badgeStyle}>{totalJobs}</span>
                        </button>
                    </div>
                    <div className="col-md-9">
                        <div style={{ marginBottom: '20px' }}>
                            <input 
                                type="text" 
                                placeholder="Search applicants" 
                                value={searchTerm} 
                                onChange={(e) => setSearchTerm(e.target.value)} 
                                style={searchInputStyle} 
                            />
                            <button onClick={() => handleTabChange('all')} style={getTabStyle(activeTab === 'all')}>All</button>
                            <button onClick={() => handleTabChange('shortlisted')} style={getTabStyle(activeTab === 'shortlisted')}>Shortlisted</button>
                            <button onClick={() => handleTabChange('rejected')} style={getTabStyle(activeTab === 'rejected')}>Rejected</button>
                        </div>
                        {filteredApplicants.length > 0 ? filteredApplicants.map((applicant) => (
                            <div key={applicant.id} style={cardStyle}>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                    <img src={applicant.profileImageUrl} alt="Profile" style={{ borderRadius: '50%', width: '80px', height: '80px', objectFit: 'cover', marginRight: '15px' }} />
                                    <div>
                                        <h5>{applicant.name}</h5>
                                        <p style={{ margin: 0 }}>Email: {applicant.email}</p>
                                        <p style={{ margin: 0 }}>Phone: {applicant.phone}</p>
                                        <p style={{ margin: 0 }}><strong>Job Title:</strong> {applicant.jobTitle}</p>
                                        <p style={{ margin: 0 }}><strong>Industry:</strong> {applicant.industry}</p>
                                        <p style={{ margin: 0 }}><strong>Type:</strong> {applicant.jobType}</p>
                                        <p style={{ margin: 0 }}><strong>Description:</strong> {applicant.jobDescription}</p>
                                    </div>
                                </div>
                                {applicant.videoURL && (
                                    <div style={{ marginBottom: '15px' }}>
                                        <label>Video Introduction:</label>
                                        <video controls src={applicant.videoURL} style={{ width: '100%', maxHeight: '250px', display: 'block' }}></video>
                                    </div>
                                )}
                                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                    <button onClick={() => handleShortlist(applicant.id)} style={actionButtonStyle}>Shortlist</button>
                                    <button onClick={() => navigate(`/profile/${applicant.id}`)} style={actionButtonStyle}>View Profile</button>
                                    <button onClick={() => handleReject(applicant.id)} style={{ ...actionButtonStyle, backgroundColor: '#ff6b6b' }}>Reject</button>
                                </div>
                            </div>
                        )) : <p>No applicants found.</p>}
                        <nav style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                            <ul style={{ listStyle: 'none', padding: 0, display: 'flex', gap: '8px' }}>
                                {pageNumbers.map(number => (
                                    <li key={number} style={{ cursor: 'pointer' }} onClick={() => paginate(number)}>
                                        <span style={{ padding: '6px 12px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: currentPage === number ? '#007bff' : '#fff', color: currentPage === number ? '#fff' : '#007bff' }}>
                                            {number}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
};


const sidebarButtonStyle = {
    display: 'block',
    width: '100%',
    marginBottom: '10px',
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
};

const badgeStyle = {
    display: 'inline-block',
    backgroundColor: '#ffc107',
    color: '#333',
    borderRadius: '10px',
    padding: '2px 5px',
    marginLeft: '5px',
    fontSize: '14px',
};

const searchInputStyle = {
    padding: '10px',
    margin: '0 10px 10px 0',
    border: '1px solid #ccc',
    borderRadius: '5px',
    width: 'calc(100% - 120px)',
};

const getTabStyle = isActive => ({
    padding: '10px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    backgroundColor: isActive ? '#007bff' : '#f8f9fa',
    color: isActive ? '#fff' : '#333',
    marginRight: '10px',
});

const cardStyle = {
    padding: '15px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    backgroundColor: '#fff',
};

const actionButtonStyle = {
    padding: '6px 12px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    backgroundColor: '#28a745',
    color: '#fff',
};

export default Applications;
