import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import logoDark from "../assets/images/logo-dark.png";
import logoWhite from "../assets/images/logo-white.png";
import logoLight from "../assets/images/logo-light.png";
import client from "../assets/images/team/01.jpg";
import { LuSearch } from "../assets/icons/vander";
import expertsignup from "../pages/expertsignup";

export default function Navbar({ navClass, navLight }) {
    const [isOpen, setMenu] = useState(true);
    const [scroll, setScroll] = useState(false);

    const [cartitem, setCartitem] = useState(false);
    const [manu, setManu] = useState('');
    const location = useLocation();

    useEffect(() => {
        const current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
        setManu(current);
    }, [location.pathname]);

    useEffect(() => {
        const scrollHandler = () => {
            setScroll(window.scrollY > 50);
        };
        window.addEventListener('scroll', scrollHandler);

        const cleanup = () => {
            window.removeEventListener('scroll', scrollHandler);

        
        };

        // const searchModal = (e) => {
        //     if (e.target.id !== 'searchItem' && !document.getElementById('searchItem').contains(e.target)) {
        //         setSearch(false);
        //     }
        // };
        // document.addEventListener('mousedown', searchModal);

        // const cartModal = (e) => {
        //     if (e.target.id !== 'isToggle' && !document.getElementById('cartDropdown').contains(e.target)) {
        //         setCartitem(false);
        //     }
        // };
      

        window.scrollTo(0, 0);

        return cleanup;
    }, []);

    const toggleMenu = () => {
        setMenu(!isOpen);
    };

    return (
        <header id="topnav" className={`${scroll ? 'nav-sticky' : ''} ${navClass}`}>
            <div className="container">
                <Link className="logo" to="/">
                    <span className="logo-light-mode" style={{borderRadius: '50%',
        height: '40px;'}}>
                        <img src={navLight ? logoDark : logoWhite} className="l-dark" alt="" style={{borderRadius: '50%',
        height: '40px;'}}/>
                        <img src={logoLight} className="l-light" alt="" />
                    </span>
                    <img src={navLight ? logoLight : logoWhite} className="logo-dark-mode" alt=""style={{borderRadius: '50%',
        height: '40px;'}} />
                </Link>

                <div className="menu-extras">
                    <div className="menu-item">
                        <Link to='#' className="navbar-toggle" id="isToggle" onClick={toggleMenu}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <div id="navigation">
                    <ul className="navigation-menu nav-right nav-light">
                        <li className={`${["", "index", "index-two", "index-three"].includes(manu) ? "active" : ""} has-submenu parent-menu-item`}>
                            <Link to="/index">Home</Link>
                        </li>

                        <li className={`${["job-categories", "job-grid-one", "job-grid-two", "job-grid-three", "job-grid-four", "job-list-one", "job-list-two", "job-detail-one", "job-detail-two", "job-detail-three", "job-apply", "job-post", "career"].includes(manu) ? "active" : ""} has-submenu parent-menu-item`}>
                            <Link to="/job-grid-one"> Jobs </Link>
                        </li>

                        <li className={`${["aboutus", "services", "pricing", "helpcenter-overview", "helpcenter-faqs", "helpcenter-guides", 'helpcenter-support', "blogs", "blog-sidebar", "blog-detail", "login", "signup", "reset-password", "applicants", "terms", "privacy"].includes(manu) ? "active" : ""} has-submenu parent-menu-item`}>
                            <Link to="#">About</Link>
                        </li>

                        <li className={manu === "contactus" ? "active" : ""}><Link to="/contactus" className="sub-menu-item">Contact Us</Link></li>

                        <li className={manu === "login" ? "active" : ""}><Link to="/clientlogin" className="sub-menu-item">Login as a Client</Link></li>
                        <li>
                            <button className="btn btn-sm btn-icon btn-pills btn-primary" style={{ backgroundColor: 'white', width: 'auto', marginTop: '16px' }}>
                                <Link to="/expertlogin" className="text-decoration-none text-dark" style={{ padding: '8px 20px' }}>Login as an Expert</Link>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
}
