import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { db, storage } from '../firebase';
import { doc, updateDoc, addDoc, collection } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

// Modern, cohesive styles for the popup
const styles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 1050,
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '30px',
    borderRadius: '10px',
    maxWidth: '500px',
    width: '80%',
    maxHeight: '90vh',
    overflowY: 'auto',
    boxShadow: '0 12px 24px rgba(0,0,0,0.15)',
    border: 'none',
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

const EditJobPopup = ({ isOpen, onClose, job }) => {
  const auth = getAuth();
  const [editedJob, setEditedJob] = useState({ ...job });

  useEffect(() => {
    if (job) {
      setEditedJob(job);
    }
  }, [job]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedJob((prevJob) => ({ ...prevJob, [name]: value }));
  };

  const uploadFileAndGetURL = async (file) => {
    const fileRef = ref(storage, `jobs/${auth.currentUser.uid}/${file.name}`);
    await uploadBytes(fileRef, file);
    return getDownloadURL(fileRef);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    const url = await uploadFileAndGetURL(file);
    setEditedJob((prevJob) => ({ ...prevJob, logo: url }));
  };

  const handleUpdateJob = async (e) => {
    e.preventDefault();
    if (editedJob.id) {
      try {
        await updateDoc(doc(db, 'jobs', editedJob.id), editedJob);
        await addDoc(collection(db, 'notifications'), {
          email: auth.currentUser.email,
          message: `You have edited the ${editedJob.title} job.`,
          timestamp: new Date()
        });
        onClose();
      } catch (error) {
        console.error('Error updating job:', error);
      }
    } else {
      console.error('Job data is incomplete:', editedJob);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={styles}
      ariaHideApp={false}
    >
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Edit Job Details</h2>
      <form onSubmit={handleUpdateJob} style={{ width: '100%' }}>
        {Object.entries({
          title: 'text',
          description: 'textarea',
          address: 'text',
          category: 'text',
          country: 'text',
          experience: 'text',
          industry: 'text',
          logo: 'file',
          minSalary: 'number',
          maxSalary: 'number',
          qualifications: 'textarea',
          salaryType: 'text',
          skills: 'textarea',
          state: 'text',
          type: 'text',
        }).map(([key, type]) => (
          type === 'file' ? (
            <div key={key} style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '8px' }}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
              <input
                type="file"
                style={{
                  width: '100%',
                  padding: '8px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  cursor: 'pointer',
                }}
                onChange={handleFileChange}
              />
            </div>
          ) : type === 'textarea' ? (
            <div key={key} style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '8px' }}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
              <textarea
                style={{
                  width: '100%',
                  height: '100px',
                  padding: '8px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                }}
                name={key}
                value={editedJob[key] || ''}
                onChange={handleInputChange}
              />
            </div>
          ) : (
            <div key={key} style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '8px' }}>{key.charAt(0).toUpperCase() + key.slice(1)}:</label>
              <input
                type={type}
                style={{
                  width: '100%',
                  padding: '8px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                }}
                name={key}
                value={editedJob[key] || ''}
                onChange={handleInputChange}
              />
            </div>
          )
        ))}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <button
            type="submit"
            style={{
              background: '#4CAF50',
              color: 'white',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Update Job
          </button>
          <button
            type="button"
            onClick={onClose}
            style={{
              background: '#f44336',
              color: 'white',
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default EditJobPopup;
