import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth, googleAuthProvider } from '../firebase';
import ProfLogo from '../assets/images/login.jpg'; // Ensure the image path matches your project structure

const ExpertLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLoginWithEmail = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/expertdashboard');
    } catch (error) {
      alert('Failed to log in: ' + error.message);
    }
  };

  const handleLoginWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleAuthProvider);
      navigate('/expertdashboard');
    } catch (error) {
      alert('Failed to log in with Google: ' + error.message);
    }
  };

  const handleResetPassword = () => {
    navigate('/reset-password'); // Make sure the '/resetpassword' route is configured in your Router
  };

  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '100vh',
      backgroundColor: '#eceff1',
      padding: '0 10%',
    },
    imageContainer: {
      flex: 1,
      marginRight: '5%',
    },
    formContainer: {
      flex: 1,
      backgroundColor: 'white',
      padding: '60px',
      borderRadius: '20px',
      boxShadow: '0 10px 40px rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    input: {
      width: '80%',
      padding: '18px 15px',
      marginBottom: '10px',
      border: '2px solid #bdbdbd',
      borderRadius: '50px',
      fontSize: '12px',
      color: '#424242',
      height: '40px',
      outline: 'none',
      transition: 'border-color 0.3s',
      '&:focus': {
        borderColor: '#007bff',
      },
    },
    button: {
      padding: '8px 0',
      width: '50%',
      borderRadius: '50px',
      border: 'none',
      cursor: 'pointer',
      fontSize: '16px',
      transition: 'opacity 0.3s',
      '&:hover': {
        opacity: 0.9,
      },
    },
    loginButton: {
      backgroundColor: '#007bff',
      color: 'white',
      marginBottom: '10px', // Adjusted for layout
    },
    googleButton: {
      backgroundColor: '#db4437',
      color: 'white',
      marginBottom: '20px', // Added margin for spacing
    },
    resetPasswordLink: {
      color: '#007bff',
      cursor: 'pointer',
      textAlign: 'center',
      marginBottom: '20px', // Ensuring consistent spacing
    },
    signupLink: {
      color: '#007bff',
      cursor: 'pointer',
      textAlign: 'center',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.imageContainer}>
        <img src={ProfLogo} alt="Login" style={{ maxWidth: '450px', borderRadius: '20px', boxShadow: '0 4px 20px rgba(0,0,0,0.2)' }} />
      </div>
      <div style={styles.formContainer}>
        <h2>Welcome Back!</h2>
        <input
          style={styles.input}
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          style={styles.input}
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div
          style={styles.resetPasswordLink}
          onClick={handleResetPassword}
        >
          Forgot password?
        </div>
        <button
          style={{ ...styles.button, ...styles.loginButton }}
          onClick={handleLoginWithEmail}
        >
          Log In
        </button>
        <button
          style={{ ...styles.button, ...styles.googleButton }}
          onClick={handleLoginWithGoogle}
        >
          Continue with Google
        </button>
        <div
          style={styles.signupLink}
          onClick={() => navigate('/register')}
        >
          Don't have an account? Sign up
        </div>
      </div>
    </div>
  );
};

export default ExpertLogin;
