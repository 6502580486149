import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faBriefcase, faEye, faHeart } from '@fortawesome/free-solid-svg-icons';
import { auth, db } from '../firebase';
import ExpertNavbar from './expertnavbar';

function MyJobs() {
  const [user] = useAuthState(auth);
  const [applications, setApplications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchApplications() {
      if (user) {
        const applicationsQuery = query(
          collection(db, 'jobApplications'),
          where('email', '==', user.email)
        );
        const querySnapshot = await getDocs(applicationsQuery);
        const fetchedApplications = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setApplications(fetchedApplications);
      }
    }

    fetchApplications();
  }, [user]);

  const viewJobDetails = (jobId) => {
    navigate(`/jobs/${jobId}`);
  };

  const applyToJob = (jobId) => {
    navigate(`/apply/${jobId}`);
  };

  const saveJob = (jobId) => {
    console.log(`Saved job: ${jobId}`);
  };

  // Styling for the application cards
  const cardStyle = {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '20px',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    boxShadow: '0 2px 6px rgba(0,0,0,0.12)',
    transition: 'transform 0.3s ease',
    cursor: 'pointer',
    ':hover': {
      transform: 'scale(1.03)',
      boxShadow: '0 4px 12px rgba(0,0,0,0.16)'
    }
  };

  const buttonStyle = {
    padding: '10px 15px',
    margin: '5px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
  };

  const buttonStyle2 = { ...buttonStyle, backgroundColor: 'gray', color: 'white' };

  return (
    <>
      <ExpertNavbar />
      <div style={{ maxWidth: '1200px', margin: '40px auto', padding: '20px' }}>
        <h2 style={{ fontSize: '24px', textAlign: 'center', margin: '30px 0' }}>My Applied Jobs</h2>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
          {applications.length > 0 ? (
            applications.map((job) => (
              <div key={job.id} style={cardStyle} onClick={() => viewJobDetails(job.id)}>
                <img src={job.logo || 'path/to/default/logo.png'} alt="Company Logo" style={{ width: '60px', height: '60px', marginRight: '20px' }} />
                <div style={{ flex: 1 }}>
                  <h3 style={{ margin: 0, fontSize: '16px', fontWeight: 'bold' }}>{job.title}</h3>
                  <p style={{ margin: '5px 0', color: '#555' }}><FontAwesomeIcon icon={faClock} /> {job.experience} years</p>
                  <p style={{ margin: '5px 0', color: '#555' }}><FontAwesomeIcon icon={faBriefcase} /> {job.type}</p>
                  <p style={{ margin: '5px 0', color: '#555' }}>{job.description}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <button style={buttonStyle} onClick={(e) => { e.stopPropagation(); applyToJob(job.id); }}>Apply Now</button>
                  <button style={buttonStyle2} onClick={(e) => { e.stopPropagation(); saveJob(job.id); }}><FontAwesomeIcon icon={faHeart} /> Save</button>
                </div>
              </div>
            ))
          ) : (
            <div style={{ textAlign: 'center' }}>You haven't applied to any jobs yet.</div>
          )}
        </div>
      </div>
    </>
  );
}

export default MyJobs;
