import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut as firebaseSignOut } from 'firebase/auth';
import { collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../firebase';
import ClientSidebar from './clientsidebar';
import ClientNavbar from './clientnavbar';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import MessagingComponent from '../components/messaging';
import bookImg from "../assets/images/book.jpg";
import profImg from "../assets/images/unnamed.svg";
import { GiBriefcase, GiPaperClip, GiThreeFriends } from 'react-icons/gi'; // Ensure these are at the top
import heroImage from "../assets/images/hero/bg3.jpg"
import styled from 'styled-components';
import { FaEnvelope, FaUserCircle, FaVideo } from 'react-icons/fa';

function ClientDashboard() {
  
  const navigate = useNavigate();
  const [jobsData, setJobsData] = useState([]);
  const [applicationsData, setApplicationsData] = useState([]);
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const messageInputRef = useRef(null);
  const [user, setUser] = useState(null);
  const [candidatesData, setCandidatesData] = useState([]);
  const [showMessagingPopup, setShowMessagingPopup] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [messages, setMessages] = useState([]);


    useEffect(() => {
      const unsubscribeAuth = onAuthStateChanged(auth, currentUser => {
        if (!currentUser) {
          navigate('/clientlogin');
        } else {
          setUser(currentUser);
          fetchCandidatesData();
        }
      });
      return () => unsubscribeAuth();
    }, [navigate]);
  
    const fetchCandidatesData = async () => {
      const profilesSnapshot = await getDocs(collection(db, "profiles"));
      const profilesData = profilesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCandidatesData(profilesData);
    };
  
    const signOutHandler = async () => {
      await firebaseSignOut(auth);
      navigate('/clientlogin');
    };
   
  
    const handleOpenMessagePopup = (candidateEmail) => {
      const selectedCandidate = candidatesData.find(candidate => candidate.email === candidateEmail);
      setSelectedCandidate(selectedCandidate);
      setShowMessagingPopup(true);
    };
    
    const handleCloseMessagePopup = () => {
      setShowMessagingPopup(false);
      setSelectedCandidate(null);
    };
  
  const handleMessageChange = e => setMessageText(e.target.value);

  const handleSendMessage = async () => {
    if (!messageText.trim()) {
      console.error("Cannot send an empty message.");
      return;
    }

    try {
      const conversationId = [user.email, selectedCandidate.email].sort().join('_');
      await addDoc(collection(db, `messages/${conversationId}/messages`), {
        senderId: user.uid,
        receiverId: selectedCandidate.id,
        text: messageText,
        timestamp: serverTimestamp()
      });
      console.log(`Message sent to ${selectedCandidate?.name}: "${messageText}"`);
      handleCloseMessagePopup();
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  const paginatedCandidates = candidatesData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );


  // Styles for the sidebar, dynamically adjusted for responsiveness
  const sidebarStyle = {
   // width: isMobile ? '100vw' : '250px', // Full width on mobile
    flexShrink: 0,
    height: '100vh',
    overflowY: 'auto',
  };

  // Styles for the main content area
  const contentStyle = {
    flex: 1,
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    minHeight: '100vh',
  };
  const styles = {
    container: `flex min-h-screen bg-[#f4f7fc] max-w-full overflow-x-hidden`,
    sidebar: `w-[250px] flex-shrink-0 h-screen overflow-y-auto`,
    content: `flex-1 p-5 flex flex-col overflow-x-hidden min-h-screen`,
    mobileSidebar: `w-full`,
    bookCallSection: `p-5 bg-no-repeat bg-center bg-cover mb-5 rounded-lg shadow-lg`,
    bookCallInner: `bg-white/80 p-5 rounded-lg backdrop-blur-sm shadow-md`,
    bookCallButton: `px-5 py-2.5 bg-blue-600 text-white rounded cursor-pointer`,
    statsCard: `text-center`,
    statsIcon: `text-4xl text-blue-600`,
    candidateCard: `p-5 border border-gray-300 rounded text-center overflow-hidden`,
    profileImage: `w-24 h-24 rounded-full mx-auto block`,
    candidateButton: `mt-2.5 bg-blue-600 text-white border-none rounded px-2.5 py-1.5 cursor-pointer`,
    viewProfileButton: `mt-2.5 ml-1.5 bg-green-600 text-white border-none rounded px-2.5 py-1.5 cursor-pointer`,
    paginationButton: `bg-white border border-gray-300 rounded px-2.5 py-1.5 mx-1 cursor-pointer`,
    paginationActiveButton: `bg-blue-600 text-white`,
    messagePopup: `fixed bottom-5 left-5 w-72 p-5 bg-white shadow-lg rounded-lg z-50`,
    messagePopupButton: `mr-2.5 bg-gray-600 text-white rounded px-2.5 py-1.5 cursor-pointer`,
    messagePopupSendButton: `bg-blue-600 text-white rounded px-2.5 py-1.5 cursor-pointer`,

    heroSection: {
      backgroundImage: `url(${heroImage})`,
      height: '40vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#FFFFFF',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      textAlign: 'center',
  },
  heroTitle: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
  },
  heroSubtitle: {
      fontSize: '1.2rem',
      maxWidth: '600px',
      padding: '0 20px',
      textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
  },
  container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'start',
      padding: '20px',
      gap: '20px',
  },
  };
  
  return (
    <>
      <ClientNavbar signOutHandler={signOutHandler} />
      <div style={styles.heroSection}>
                <h1 style={styles.heroTitle}>Welcome to Your Dashboard</h1>
                <p style={styles.heroSubtitle}>Explore the opportunities and manage your job postings effortlessly.</p>
            </div>
      <div style={{ display: 'flex', minHeight: '100vh', backgroundColor: '#f4f7fc', maxWidth: '100vw', overflowX: 'hidden' }}>
        <div style={{ width: '250px', flexShrink: 0, height: '100vh', overflowY: 'auto' }}>
          <ClientSidebar />
        </div>
        <div style={{ flex: 1, padding: '20px', display: 'flex', flexDirection: 'column', overflowX: 'hidden', minHeight: '100vh' }}>
          <BookACallSection navigate={navigate} />
          <div style={{ flex: 1, marginTop: '20px' }}>
            <StatCards jobsData={jobsData} applicationsData={applicationsData} candidatesData={candidatesData} />
            <InteractiveTitle candidatesCount={candidatesData.length} />
            <CandidatesSection candidates={candidatesData} handleOpenMessagePopup={handleOpenMessagePopup} />
          </div>
        </div>
      </div>
      {showMessagingPopup && (
        <MessagingComponent
          onClose={handleCloseMessagePopup}
          selectedCandidate={selectedCandidate}
          currentUser={user}
        />
      )}
      <Footer />
      <ScrollTop />
    </>
  );
}

function BookACallSection({ navigate }) {
  return (
    <div style={{ padding: '20px', background: `url(${bookImg}) no-repeat center center`, backgroundSize: 'cover', marginBottom: '20px' }}>
      <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '20px', borderRadius: '10px', backdropFilter: 'blur(10px)', boxShadow: '0 4px 16px rgba(0,0,0,0.1)' }}>
        <h2 style={{ color: '#333', marginBottom: '10px' }}>Book a Call</h2>
        <p style={{ color: '#666' }}>Discuss your needs with our experts.</p>
        <button onClick={() => navigate('/bookacall')} style={{ padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>Schedule Now</button>
      </div>
    </div>
  );
}






const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
`;

const StatCard = styled.div`
  padding: 20px;
  border-radius: 15px;
  background: linear-gradient(135deg, #ffffff, #f0f0f0);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  border: 1px solid #ccc;

  &:hover {
    transform: scale(1.03);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
`;

const StatIcon = styled.div`
  font-size: 40px;
  color: ${props => props.color};
  margin-bottom: 10px;
`;

const StatLabel = styled.div`
  font-size: 1.1rem;
  color: #343a40;
  font-weight: bold;
  margin-bottom: 5px;
`;

const StatCount = styled.div`
  font-size: 2.5rem;
  color: #343a40;
  font-weight: bold;
`;

function StatCards({ jobsData, applicationsData, candidatesData }) {
  const stats = [
    { icon: <GiBriefcase />, label: 'Current Job Openings', count: jobsData.length, iconColor: '#0d6efd' },
    { icon: <GiPaperClip />, label: 'Applications Received', count: applicationsData.length, iconColor: '#dc3545' },
    { icon: <GiThreeFriends />, label: 'Total Experts Registered', count: candidatesData.length, iconColor: '#28a745' },
  ];

  return (
    <StatsContainer>
      {stats.map((stat, index) => (
        <StatCard key={index} onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
          onMouseLeave={(e) => e.currentTarget.style.transform = 'none'}>
          <StatIcon color={stat.iconColor}>{stat.icon}</StatIcon>
          <StatLabel>{stat.label}</StatLabel>
          <StatCount>{stat.count}</StatCount>
        </StatCard>
      ))}
    </StatsContainer>
  );
}
const Container = styled.div`
  text-align: center;
  margin: 50px 0;
  color: rgb(10, 88, 202);
  padding: 40px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  font-size: 3rem; /* Adjusted for a more proportional appearance */
  font-weight: 700;
  margin-bottom: 20px;
  color: rgb(10, 88, 202); /* Ensuring the theme color is vibrant */
  font-family: 'Segoe UI', Arial, sans-serif;
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #666;
  margin: 0 auto 20px auto;
  max-width: 900px;
  line-height: 1.6;
  font-family: 'Segoe UI', Arial, sans-serif;
`;

const ActionButton2 = styled.button`
  padding: 12px 30px;
  border-radius: 50px;
  background-color: rgb(10, 88, 202);
  color: white;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgb(8, 76, 174);
  }
`;

function InteractiveTitle({ candidatesCount }) {
  return (
    <Container>
      <Title>Meet Our Experts</Title>
      <Description>
        Join {candidatesCount} top-tier professionals and discover collaborative success. Our experts are passionate and proficient, dedicated to bringing your vision to life with precision and flair.
      </Description>
      <ActionButton2 onClick={() => { /* Event handler logic */ }}>
        Discover More
      </ActionButton2>
    </Container>
  );
}


const CandidatesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  gap: 20px;
`;

const CandidateCard = styled.div`
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: calc(33.333% - 20px); /* Three items per row */
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 1024px) {
    width: calc(50% - 20px); /* Two items per row on smaller screens */
  }

  @media (max-width: 768px) {
    width: 100%; /* One item per row on mobile */
  }
`;

const ProfileImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
  border: 3px solid #007bff; /* Blue border around the profile image */
`;

const CandidateInfo = styled.div`
  text-align: center;
  color: #495057;
`;

const CandidateName = styled.h5`
  font-size: 1.1rem;
  font-weight: bold;
  color: #000; /* Black color for names to stand out */
  margin: 5px 0;
`;

const CandidateDescription = styled.p`
  font-size: 0.9rem;
  color: #6c757d;
  margin: 5px 0;
`;

const ActionButton = styled.button`
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:hover {
    background-color: #0056b3;
  }

  svg {
    margin-right: 5px;
  }
`;

const VideoPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 640px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

function CandidatesSection({ candidates, handleOpenMessagePopup }) {
  const navigate = useNavigate();
  const [videoPopup, setVideoPopup] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');

  const openProfilePage = (candidateId) => {
    navigate(`/candidate-profile/${candidateId}`);
  };

  const showVideoPopup = (videoUrl) => {
    setCurrentVideoUrl(videoUrl);
    setVideoPopup(true);
  };

  const closeVideoPopup = () => {
    setVideoPopup(false);
    setCurrentVideoUrl('');
  };

  return (
    <>
      <CandidatesContainer>
        {candidates.map((candidate) => (
          <CandidateCard key={candidate.id}>
            <ProfileImage src={candidate.profileImage || profImg } alt="Profile" />
            <CandidateInfo>
              <CandidateName>{candidate.name}</CandidateName>
              <CandidateDescription>{candidate.description}</CandidateDescription>
              <CandidateDescription>{candidate.currentJobTitle}</CandidateDescription>
              <CandidateDescription>{`${candidate.gender}, ${candidate.country}`}</CandidateDescription>
            </CandidateInfo>
            <ActionButton onClick={() => handleOpenMessagePopup(candidate.email)}>
              <FaEnvelope /> Message
            </ActionButton>
            <ActionButton onClick={() => openProfilePage(candidate.email)}>
              <FaUserCircle /> View Profile
            </ActionButton>
            <ActionButton onClick={() => showVideoPopup(candidate.videoIntroUrl)}>
              <FaVideo /> Intro Video
            </ActionButton>
          </CandidateCard>
        ))}
      </CandidatesContainer>
      {videoPopup && (
        <VideoPopup>
          <iframe
            width="100%"
            height="360"
            src={currentVideoUrl}
            title="Candidate Intro Video"
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <button onClick={closeVideoPopup} style={{
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: '#dc3545',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            display: 'block',
            marginLeft: 'auto',
          }}>Close</button>
        </VideoPopup>
      )}
    </>
  );
}

function Pagination({ currentPage, totalPages, onPageChange }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
      {Array.from({ length: totalPages }, (_, index) => index + 1).map(number => (
        <button key={number} onClick={() => onPageChange(number)} style={{ background: number === currentPage ? '#007bff' : '#f8f9fa', color: number === currentPage ? 'white' : 'black', border: '1px solid #ddd', borderRadius: '5px', padding: '5px 10px', margin: '0 5px', cursor: 'pointer' }}>
          {number}
        </button>
      ))}
    </div>
  );
}
function MessagePopup({ onClose, onSend, selectedCandidate, messageText, onMessageChange, messageInputRef }) {
  // Early return if no candidate is selected, to handle undefined cases safely
  if (!selectedCandidate) {
    return <div style={{ position: 'fixed', bottom: '20px', left: '20px', padding: '20px', zIndex: 1000 }}>Please select a candidate to message.</div>;
  }

  return (
    <div style={{
      position: 'fixed',
      bottom: '20px',
      left: '20px',
      width: '300px',
      padding: '20px',
      background: '#fff',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      zIndex: 1000,
    }}>
      <h4>Message to {selectedCandidate.name}</h4>
      <textarea
        ref={messageInputRef}
        value={messageText}
        onChange={onMessageChange}
        style={{
          width: '100%',
          height: '100px',
          marginBottom: '10px',
          padding: '10px',
          borderRadius: '4px',
          border: '1px solid #ccc',
        }}
        required
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button onClick={onClose} style={{ marginRight: '10px', backgroundColor: '#6c757d', color: 'white', border: 'none', borderRadius: '5px', padding: '5px 10px', cursor: 'pointer' }}>
          Close
        </button>
        <button onClick={onSend} style={{ backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '5px', padding: '5px 10px', cursor: 'pointer' }}>
          Send
        </button>
      </div>
    </div>
  );
}


export default ClientDashboard;
