import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase"; // Ensure paths match your project structure
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import { FiMapPin, FiClock, FiDollarSign, FiDribbble, FiLinkedin, FiFacebook, FiInstagram, FiTwitter } from "../assets/icons/vander";
import { getDoc } from 'firebase/firestore';
import { useEffect } from 'react';


export default function EmployerProfile() {
  const params = useParams();
  const id = params.id;
  const [employerDetails, setEmployerDetails] = useState({
    name: "",
    city: "",
    image: "",
    description: "",
    vacancies: [],
  });

  // Function to fetch employer details from the database
  const fetchEmployerDetails = async () => {
    try {
      // Assuming you have a collection named 'employers' in your Firestore database
      const docRef = collection(db, "employers", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setEmployerDetails(docSnap.data());
      } else {
        console.log("No such document exists!");
      }
    } catch (error) {
      console.error("Error fetching employer details:", error);
    }
  };

  // Fetch employer details when the component mounts
  useEffect(() => {
    fetchEmployerDetails();
  }, []);

  // Function to handle form submission (sending a message to the employer)
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Your submission logic here
    // For example, sending a message to the employer
  };

  // Function to handle file upload (employer logo)
  const handleLogoUpload = (file) => {
    // Your file upload logic here
  };

  // Function to update employer details
  const updateEmployerDetails = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      handleLogoUpload(files[0]);
    } else {
      setEmployerDetails({ ...employerDetails, [name]: value });
    }
  };

  return (
    <>
      <Navbar navClass="defaultscroll sticky" navLight={true} />
      <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${employerDetails.image})`, backgroundPosition: "center" }}>
        <div className="bg-overlay bg-gradient-overlay-2"></div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 mt-4">
              <div className="features-absolute">
                <div className="d-md-flex justify-content-between align-items-center bg-white shadow rounded p-4">
                  <div className="d-flex align-items-center">
                    <img src={employerDetails.image} className="avatar avatar-md-md rounded shadow p-3 bg-white" alt="" />

                    <div className="ms-3">
                      <h5>{employerDetails.name}</h5>
                      <span className="text-muted d-flex align-items-center">
                        <FiMapPin className="fea icon-sm me-1" />
                        {employerDetails.city}, Australia
                      </span>
                    </div>
                  </div>

                  <div className="mt-4 mt-md-0">
                    <Link to="" className="btn btn-sm btn-primary me-1">
                      Follow
                    </Link>
                    <Link to="/job-grid-one" className="btn btn-sm btn-soft-primary">
                      See Jobs
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row g-4">
            <div className="col-lg-8 col-md-7 col-12">
              <h4 className="mb-4">Company Story:</h4>

              <p className="text-muted">{employerDetails.description}</p>

              {/* Assuming you have multiple images for the employer */}
              <div className="row g-4">
                {employerDetails.images &&
                  employerDetails.images.map((image, index) => (
                    <div className="col-6" key={index}>
                      <img src={image} className="rounded shadow img-fluid" alt="" />
                    </div>
                  ))}
              </div>

              <h4 className="my-4">Vacancies:</h4>

              <div className="row g-4">
                {employerDetails.vacancies &&
                  employerDetails.vacancies.map((vacancy, index) => (
                    <div className="col-lg-6 col-12" key={index}>
                      <div className="job-post rounded shadow bg-white">
                        <div className="p-4">
                          <Link to={`/job-detail-one/${vacancy.id}`} className="text-dark title h5">
                            {vacancy.title}
                          </Link>

                          <p className="text-muted d-flex align-items-center small mt-3">
                            <FiClock className="fea icon-sm text-primary me-1" />
                            Posted {vacancy.posted} Days ago
                          </p>

                          <ul className="list-unstyled d-flex justify-content-between align-items-center mb-0 mt-3">
                            <li className="list-inline-item">
                              <span className="badge bg-soft-primary">{vacancy.jobTime}</span>
                            </li>
                            <li className="list-inline-item">
                              <span className="text-muted d-flex align-items-center small">
                                <FiDollarSign className="fea icon-sm text-primary me-1" />
                                {vacancy.salary}/mo
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="d-flex align-items-center p-4 border-top">
                          <img src={vacancy.image} className="avatar avatar-small rounded shadow p-3 bg-white" alt="" />

                          <div className="ms-3">
                            <h6>{vacancy.name}</h6>
                            <span className="text-muted d-flex align-items-center">
                              <FiMapPin className="fea icon-sm me-1" />
                              {vacancy.country}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-lg-4 col-md-5 col-12">
              <div className="card bg-white p-4 rounded shadow sticky-bar">
                {/* Assuming you have employer's location details */}
                <div className="map map-400px border-0">
                  {/* Embed Google Maps or any other map service */}
                </div>

                <div className="mt-3">
                  {/* Display other details like founding year, headquarters, number of employees, website, and social media links */}
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <span className="text-muted fw-medium">Founded:</span>
                    <span>{employerDetails.founded}</span>
                  </div>

                  {/* Include more details as needed */}

                  <div className="mt-4 pt-4 border-top">
                    <h5>Get in touch!</h5>
                    <form className="mt-4" onSubmit={handleSubmit}>
                      {/* Form for sending a message to the employer */}
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label fw-semibold">
                              Your Name <span className="text-danger">*</span>
                            </label>
                            <input name="name" id="name" type="text" className="form-control" placeholder="Name :" onChange={updateEmployerDetails} />
                          </div>
                        </div>

                        {/* Add more form fields for email, subject, and message */}

                        <div className="col-12">
                          <div className="d-grid">
                            <button type="submit" id="submit" name="send" className="btn btn-primary">
                              Send Message
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Section for displaying related companies */}
        <div className="container mt-100 mt-60">
          <div className="row justify-content-center mb-4 pb-2">
            <div className="col-12">
              <div className="section-title text-center">
                <h4 className="title mb-3">Related Companies</h4>
                <p className="text-muted para-desc mx-auto mb-0">Search all the open positions on the web. Get your own personalized salary estimate. Read reviews on over 30000+ companies worldwide.</p>
              </div>
            </div>
          </div>

          <div className="row">
            {/* Render related companies */}
            {employerDetails.relatedCompanies &&
              employerDetails.relatedCompanies.map((company, index) => (
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-5" key={index}>
                  <div className="employer-card position-relative bg-white rounded shadow p-4 mt-3">
                    <div className="employer-img d-flex justify-content-center align-items-center bg-white shadow-md rounded">
                      <img src={company.logo} className="avatar avatar-ex-small" alt="" />
                    </div>

                    <div className="content mt-3">
                      <Link to={`/employer-profile/${company.id}`} className="title text-dark h5">
                        {company.name}
                      </Link>

                      <p className="text-muted mt-2 mb-0">{company.description}</p>
                    </div>

                    <ul className="list-unstyled d-flex justify-content-between align-items-center border-top mt-3 pt-3 mb-0">
                      <li className="text-muted d-inline-flex align-items-center">
                        <FiMapPin className="fea icon-sm me-1 align-middle" />
                        {company.country}
                      </li>
                      <li className="list-inline-item text-primary fw-medium">{company.vacancy} Jobs</li>
                    </ul>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      <Footer top={true} />
      <ScrollTop />
    </>
  );
}

           
