import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth, googleAuthProvider } from '../firebase';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore'; // Import Firestore operations

const ClientLoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const db = getFirestore(); // Initialize Firestore

  const handleLoginWithEmail = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      // Proceed to check and assign role in Firestore
      await validateUserRole(user, 'client');
    } catch (error) {
      alert('Failed to log in: ' + error.message);
    }
  };

  const handleLoginWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      const user = result.user;
      // Proceed to check and assign role in Firestore
      await validateUserRole(user, 'client');
    } catch (error) {
      alert('Failed to log in with Google: ' + error.message);
    }
  };

  const validateUserRole = async (user, expectedRole) => {
    const userRef = doc(db, 'users', user.uid);
    const docSnap = await getDoc(userRef);

    if (docSnap.exists() && docSnap.data().role !== expectedRole) {
      alert(`Access Denied: Your account does not have ${expectedRole} privileges.`);
      return;
    }

    // If user doesn't have a role, assign them the expectedRole
    if (!docSnap.exists() || docSnap.data().role !== expectedRole) {
      await setDoc(userRef, { email: user.email, role: expectedRole }, { merge: true });
    }

    navigate('/clientdashboard');
  };

  const handleResetPassword = () => {
    navigate('/clientreset'); // Assuming '/resetpassword' is the route for the reset password page
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: '#eceff1',
    },
    formContainer: {
      minWidth: '320px',
      maxWidth: '400px',
      padding: '40px',
      borderRadius: '20px',
      boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
    },
    input: {
      width: '100%',
      padding: '15px',
      marginBottom: '10px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      fontSize: '14px',
    },
    button: {
      width: '100%',
      padding: '12px',
      margin: '10px 0',
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer',
      fontSize: '16px',
    },
    loginButton: {
      backgroundColor: '#007bff',
      color: 'white',
    },
    googleButton: {
      backgroundColor: '#db4437',
      color: 'white',
    },
    resetPasswordLink: {
      color: '#007bff',
      cursor: 'pointer',
      textAlign: 'center',
      marginBottom: '10px', // Space above the sign-up link
    },
    signupLink: {
      marginTop: '20px',
      color: '#007bff',
      cursor: 'pointer',
      textAlign: 'center',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h2 style={{ textAlign: 'center', marginBottom: '25px' }}>Client Login</h2>
        <input
          style={styles.input}
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          style={styles.input}
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div
          style={styles.resetPasswordLink}
          onClick={handleResetPassword}
        >
          Forgot password?
        </div>
        <button
          style={{ ...styles.button, ...styles.loginButton }}
          onClick={handleLoginWithEmail}
        >
          Log In
        </button>
        <button
          style={{ ...styles.button, ...styles.googleButton }}
          onClick={handleLoginWithGoogle}
        >
          Continue with Google
        </button>
        <div
          style={styles.signupLink}
          onClick={() => navigate('/register')}
        >
          Don't have an account? Sign up
        </div>
      </div>
    </div>
  );
};

export default ClientLoginScreen;
