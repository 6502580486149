import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link } from "react-router-dom";
import { collection, getDocs } from 'firebase/firestore';
import { db, auth } from "../firebase";
import bg1 from "../assets/images/hero/bg.jpg";
// import Navbar from '/dashboard';

import ScrollTop from '../components/scrollTop';
import { FiCheckCircle, FiMail } from "react-icons/fi";
import { BsCheckCircleFill } from "react-icons/bs"; // Import a tick icon
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ExpertsSlider = () => {
    const [candidatesData, setCandidatesData] = useState([]);
    const [user] = useAuthState(auth);
    const extractText = (htmlString) => {
        const tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = htmlString;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    };

    useEffect(() => {
        const fetchCandidates = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "profiles"));
                const candidates = querySnapshot.docs.map(doc => {
                    const profile = doc.data();
                    const textContent = extractText(profile.workExperience);
                    const workExperienceSnippet = textContent.split(" ").slice(0, 10).join(" ") + "..."; // Get first 50 words of text content
                    return {
                        id: doc.id,
                        name: profile.name,
                        currentJobTitle: profile.currentJobTitle,
                        imageUrl: user?.photoURL || profile.imageUrl,
                        email: profile.email,
                        workExperience: workExperienceSnippet,
                    };
                });
                setCandidatesData(candidates);
            } catch (error) {
                console.error("Error fetching candidates data: ", error);
            }
        };

        fetchCandidates();
    }, [user]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5, // Adjusted to show 5 slides by default
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768, // Breakpoint for tablets and mobile devices
                settings: {
                    slidesToShow: 1, // Change to show only one slide on smaller screens
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            {/* <Navbar /> */}
            <div className="slider-section">
                <Slider {...settings}>
                    {candidatesData.map((item, index) => (
                        <div key={index}>
                            <div className="card candidate-card shadow-lg mb-4" style={{ width: '80%' }}>
                                <div className="card-body text-center">
                                    <img src={item.imageUrl} className="img-fluid rounded-circle mb-2" alt={item.name} style={{justifyContent:'center !important', alignContent:'center !important', margin: '0 auto' }}/>
                                    <h5 className="card-title">{item.name}</h5>
                                    <p className="card-text">{item.currentJobTitle}</p>
                                    <span className="badge bg-success"><BsCheckCircleFill /> Verified Expert</span>
                                    <Link to={`/roleform`} className="btn btn-primary">View Profile</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </>
    );
};

export default ExpertsSlider;
