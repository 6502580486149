import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import backgroundImageUrl from '../assets/images/5191079.jpg'
// You should replace 'backgroundImageUrl' with the path to your actual background image.


const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setMessage('Check your inbox for further instructions');
      })
      .catch((error) => {
        setError('Failed to reset password');
        console.error('Error sending password reset email', error);
      });
  };

  const customOrange = '#3b82f6'; // The RGB color for the button

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div style={{ flex: 1, backgroundImage: `url(${backgroundImageUrl})`, backgroundSize: 'cover' }}>
        {/* Background image side */}
      </div>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        {/* Form side */}
        <h2>Welcome back!</h2>
        <p>Enter your email to reset your password.</p>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {message && <p style={{ color: 'green' }}>{message}</p>}
        <form onSubmit={handleSubmit} style={{ width: '300px' }}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ccc' }}
            required
          />
          <button type="submit" style={{ width: '100%', padding: '10px', backgroundColor: customOrange, color: 'white', border: 'none' }}>
            Send Email
          </button>
        </form>
        <p>
          Remember your password? <span onClick={() => navigate('/clientlogin')} style={{ color: customOrange, cursor: 'pointer' }}>Log In</span>
        </p>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
