import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut as firebaseSignOut } from 'firebase/auth';
import { auth, db } from '../firebase';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCommentDots, faUserCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import MessagingComponent from '../components/messaging';
import logoDark from "../assets/images/logo-dark.png";

function ExpertNavbar() {
  const [user, setUser] = useState(null);
  const [showMessagingPopup, setShowMessagingPopup] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const navigate = useNavigate();

  const fetchNotifications = (userEmail) => {
    const notificationsRef = query(collection(db, "expertsNotification"), where("email", "==", userEmail), orderBy("timestamp", "desc"));
    return onSnapshot(notificationsRef, (snapshot) => {
      const fetchedNotifications = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp.toDate()
      }));
      setNotifications(fetchedNotifications);
    }, (error) => {
      console.error("Error fetching notifications: ", error);
    });
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
        fetchNotifications(firebaseUser.email);
      } else {
        setUser(null);
        navigate('/expertlogin');
      }
    });
    return () => unsubscribeAuth();
  }, [navigate]);

  useEffect(() => {
    if (!user) {
      setNotifications([]);
      return;
    }
    const unsubscribeNotifications = fetchNotifications(user.email);
    return () => unsubscribeNotifications();
  }, [user]);

  const signOut = async () => {
    await firebaseSignOut(auth);
    navigate('/expertlogin');
  };

  const toggleMessagingPopup = () => {
    setShowMessagingPopup(!showMessagingPopup);
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const hasUnreadNotifications = notifications.length > 0;

  return (
    <>
      <nav style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#0a58ca',
        color: 'white',
        padding: '0.5rem 2rem'
      }}>
        <div className="logo">
          <img src={logoDark} alt="Logo" style={{ height: '50px', borderRadius: '50%' }} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faBell} style={{
            color: 'white',
            margin: '0 10px',
            fontSize: '16px',
            cursor: 'pointer',
            position: 'relative',
            animation: hasUnreadNotifications ? 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both' : 'none'
          }} onClick={toggleNotifications} />
          <FontAwesomeIcon icon={faCommentDots} style={{ color: 'white', margin: '0 10px', fontSize: '16px', cursor: 'pointer' }} onClick={toggleMessagingPopup} />
          {user ? (
            <>
              <span style={{ color: 'white', marginRight: '10px' }}>Hello, {user.displayName || 'Guest'}</span>
              <img src={user.photoURL || 'path/to/default/profile-image.png'} alt="Profile" style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                cursor: 'pointer',
                objectFit: 'cover',
                marginLeft: '10px'
              }} />
              <FontAwesomeIcon icon={faSignOutAlt} style={{
                color: 'red',
                margin: '0 10px',
                fontSize: '20px',
                cursor: 'pointer'
              }} onClick={signOut} />
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faUserCircle} style={{ color: 'white', margin: '0 10px', fontSize: '16px', cursor: 'pointer' }} onClick={() => navigate('/login')} />
              <span style={{ color: 'white', cursor: 'pointer' }} onClick={() => navigate('/login')}>Login</span>
            </>
          )}
        </div>
      </nav>
      {showMessagingPopup && <MessagingComponent onClose={() => setShowMessagingPopup(false)} user={user} />}
      {showNotifications && (
        <div style={{
          position: 'absolute',
          top: '60px',
          right: '10px',
          backgroundColor: 'white',
          border: '1px solid #ccc',
          borderRadius: '5px',
          width: '300px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          overflow: 'auto',
          maxHeight: '400px',
          zIndex: 1000
        }}>
          {notifications.map(notification => (
            <div key={notification.id} style={{
              padding: '10px',
              borderBottom: '1px solid #eee',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <p>{notification.notification}</p>
              <p>{notification.message}</p>
              <small style={{ color: '#aaa' }}>{notification.timestamp.toLocaleDateString()}</small>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default ExpertNavbar;
