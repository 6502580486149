import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut as firebaseSignOut } from 'firebase/auth';
import { doc, setDoc, getDocs, collection, query, limit, startAfter } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faBriefcase, faHeart, faEye, faMapMarkedAlt, faDollarSign, faCalendarAlt, faGraduationCap, faGlobe, faIndustry, faQuestionCircle, faLayerGroup, faBuilding, faFlag } from '@fortawesome/free-solid-svg-icons';
import { auth, db } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import styled from 'styled-components';
import Sidebar from './expertsidebar';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import ExpertNavbar from './expertnavbar';
import heroImage from '../assets/images/hero/bg5.jpg';
import { addDoc } from 'firebase/firestore';


// Styled Components
const HeroSection = styled.div`
  background: url(${heroImage}) no-repeat center center/cover;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
`;

const HeroCaption = styled.h1`
  font-size: 2.5rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.6);
`;

const JobCard = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 6px 12px rgba(0,0,0,0.2);
  }
`;

const JobImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;  
  border: 2px solid #007bff;  
  object-fit: contain;
  margin: 20px auto;
  background: white;
`;

const JobInfo = styled.div`
  padding: 15px;
  text-align: center;
  color: #6c757d;
`;

const Title = styled.h3`
  color: #333;
  font-size: 18px;
  margin-bottom: 5px;
`;

const JobDescription = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: #6c757d;
`;

const JobActions = styled.div`
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f9fa;
`;

const JobAction = styled.button`
  background: white;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    background-color: #007bff;
    color: white;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1050;
`;

const ModalContent = styled.div`
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 15px 25px rgba(0,0,0,0.3);
  width: 90%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  color: #333;
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: transparent;
  font-size: 24px;
  color: #666;
  &:hover {
    color: #000;
    cursor: pointer;
  }
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

function ExpertDashboard() {
  const [user, loading] = useAuthState(auth);
  const [jobs, setJobs] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    const jobsQuery = query(collection(db, "jobs"), limit(10));
    const querySnapshot = await getDocs(jobsQuery);
    const jobsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setJobs(jobsData);
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
  };

  const fetchMoreJobs = async () => {
    if (lastVisible && !loading) {
      const moreJobsQuery = query(collection(db, "jobs"), startAfter(lastVisible), limit(10));
      const querySnapshot = await getDocs(moreJobsQuery);
      const moreJobsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setJobs([...jobs, ...moreJobsData]);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    }
  };

  const saveJob = async (jobId) => {
    if (!user) {
      alert("Please log in to save jobs.");
      return;
    }
    const jobData = jobs.find(job => job.id === jobId);
    if (!jobData) {
      alert("Job not found.");
      return;
    }
    const userDocRef = doc(db, "users", user.uid, "savedJobs", jobId);
    const notificationCollectionRef = collection(db, "expertsNotification");
    try {
      await setDoc(userDocRef, { jobId: jobId });
      await addDoc(notificationCollectionRef, {
        userId: user.uid,
        title: jobData.title,
        email: user.email,
        timestamp: new Date(),
        notification: `Saved job: ${jobData.title}`
      });
      alert(`Job '${jobData.title}' saved successfully.`);
    } catch (error) {
      console.error("Error saving job:", error);
      alert("Failed to save job.");
    }
  };

  const applyForJob = (job) => {
    navigate('/job-apply', { state: { jobDetails: job } });
  };

  const viewJobDetails = (job) => {
    setSelectedJob(job);
  };

  const closeJobDetails = () => {
    setSelectedJob(null);
  };

  return (
    <>
      <ExpertNavbar />
      <HeroSection>
        <HeroCaption>Find Your Dream Job</HeroCaption>
      </HeroSection>
      <div className="container-fluid" style={{ maxWidth: '1200px', margin: 'auto', padding: '20px 0' }}>
        <div className="row">
          <div className="col-md-3">
            <Sidebar />
          </div>
          <div className="col-md-9">
            {jobs.map((job) => (
              <JobCard key={job.id}>
                <JobImage src={job.logo || 'https://via.placeholder.com/150'} alt="Company Logo" />
                <JobInfo>
                  <Title>{job.title}</Title>
                  <JobDescription>
                    <FontAwesomeIcon icon={faClock} /> {job.experience} years<br />
                    <FontAwesomeIcon icon={faBriefcase} /> {job.type}
                  </JobDescription>
                  <p>{job.description}</p>
                </JobInfo>
                <JobActions>
                  <JobAction onClick={() => viewJobDetails(job)}><FontAwesomeIcon icon={faEye} /> View</JobAction>
                  <JobAction onClick={() => saveJob(job.id)}><FontAwesomeIcon icon={faHeart} /> Save</JobAction>
                  <JobAction primary onClick={() => applyForJob(job)}><FontAwesomeIcon icon={faBriefcase} /> Apply</JobAction>
                </JobActions>
              </JobCard>
            ))}
            {jobs.length >= 10 && (
              <button onClick={fetchMoreJobs} disabled={loading} style={{ padding: '10px 20px', margin: '10px auto', display: 'block', fontSize: '16px' }}>Load More</button>
            )}
          </div>
        </div>
      </div>
      {selectedJob && (
        <Modal>
          <ModalContent>
            <ModalCloseButton onClick={closeJobDetails}>×</ModalCloseButton>
            <img src={selectedJob.logo} alt="Company Logo" style={{ width: '100px', height: '100px', margin: '20px auto' }} />
            <h2 style={{ color: '#333' }}>{selectedJob.title} - {selectedJob.companyName}</h2>
            <p><FontAwesomeIcon icon={faBriefcase} /> Category: {selectedJob.category}</p>
            <p><FontAwesomeIcon icon={faBuilding} /> Company: {selectedJob.companyName}</p>
            <p><FontAwesomeIcon icon={faFlag} /> Country: {selectedJob.country}</p>
            <p><FontAwesomeIcon icon={faGlobe} /> Website: <a href={selectedJob.website} target="_blank" rel="noopener noreferrer">{selectedJob.website}</a></p>
            <p><FontAwesomeIcon icon={faIndustry} /> Industry: {selectedJob.industry}</p>
            <p><FontAwesomeIcon icon={faLayerGroup} /> Skills: {Array.isArray(selectedJob.skills) ? selectedJob.skills.join(', ') : selectedJob.skills || 'N/A'}</p>
            <p><FontAwesomeIcon icon={faGraduationCap} /> Qualifications: {Array.isArray(selectedJob.qualifications) ? selectedJob.qualifications.join(', ') : selectedJob.qualifications || 'N/A'}</p>
            <p><FontAwesomeIcon icon={faCalendarAlt} /> Expiry Date: {selectedJob.expiryDate}</p>
            <p><FontAwesomeIcon icon={faClock} /> Experience: {selectedJob.experience} years</p>
            <p><FontAwesomeIcon icon={faDollarSign} /> Salary Range: ${selectedJob.minSalary} - ${selectedJob.maxSalary} {selectedJob.salaryType}</p>
            <p><FontAwesomeIcon icon={faQuestionCircle} /> Questions: {selectedJob.questions || 'N/A'}</p>
            <p><FontAwesomeIcon icon={faMapMarkedAlt} /> State: {selectedJob.state}</p>
            <p><FontAwesomeIcon icon={faBriefcase} /> Type: {selectedJob.type}</p>
            <p>{selectedJob.description}</p>
            <ModalActions>
              <JobAction primary onClick={() => applyForJob(selectedJob)}>Apply Now</JobAction>
              <JobAction onClick={() => saveJob(selectedJob.id)}>Save Job</JobAction>
            </ModalActions>
          </ModalContent>
        </Modal>
      )}
      <Footer />
      <ScrollTop />
    </>
  );
}

export default ExpertDashboard;
