import React from "react";
import { Link } from "react-router-dom";

import bg1 from "../assets/images/hero/bg.jpg"

import Navbar from "../components/navbar";
import Faq from "../components/faq";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";

import { FiArrowRightCircle } from "../assets/icons/vander";

export default function Pricing() {
    return (
        <>
            <Navbar />
            <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top' }}>
                <div className="bg-overlay bg-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Pricing Plans</h5>
                            </div>
                        </div>
                    </div>

                    <div className="position-middle-bottom">
                        <nav aria-label="breadcrumb" className="d-block">
                            <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                <li className="breadcrumb-item"><Link to="/">Experts Blooms</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Pricing</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
            <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <div className="container">
                    <div className="row g-4 align-items-lg-center justify-content-center">
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="pricing text-center rounded position-relative shadow">
                                <div className="price-header rounded-top bg-dark bg-white-dark pt-5 pb-5">
                                    <h5 className="price-title text-white title-dark">Basic</h5>
                                    <p className="mb-0 text-white-50">Ideal for Individuals</p>
                                </div>
                                <div className="d-flex justify-content-center bg-light border-bottom py-4">
                                    <span className="h6 mb-0">$</span>
                                    <span className="price h4 mb-0 ms-1">0.00</span>
                                    <span className="h6 align-self-end mb-1">/mo</span>
                                </div>
                                <div className="pricing-features text-start bg-light p-3">
                                    <ul className="feature list-unstyled mb-0">
                                        <li className="feature-list text-muted"><FiArrowRightCircle className="fea icon-sm text-dark me-2" />Limited Job Posts</li>
                                        <li className="feature-list text-muted mt-2"><FiArrowRightCircle className="fea icon-sm text-dark me-2" />Basic Search Filters</li>
                                        <li className="feature-list text-muted mt-2"><FiArrowRightCircle className="fea icon-sm text-dark me-2" />Limited Access to Candidates</li>
                                        <li className="feature-list text-muted mt-2"><FiArrowRightCircle className="fea icon-sm text-dark me-2" />Limited Messaging</li>
                                        <li className="feature-list text-muted mt-2"><FiArrowRightCircle className="fea icon-sm text-dark me-2" />Basic Support</li>
                                    </ul>
                                    <div className="text-center py-3">
                                        <Link to="#" className="btn btn-dark">Sign Up</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="pricing text-center rounded position-relative overflow-hidden shadow">
                                <div className="ribbon ribbon-right overflow-hidden"><span className="text-center bg-warning d-block shadow small h6">Recommended</span></div>
                                <div className="price-header rounded-top bg-dark bg-white-dark pt-5 pb-5">
                                    <h5 className="price-title text-white title-dark">Premium</h5>
                                    <p className="mb-0 text-white-50">Ideal for Businesses</p>
                                </div>
                                <div className="d-flex justify-content-center bg-light border-bottom py-4">
                                    <span className="h6 mb-0">$</span>
                                    <span className="price h4 mb-0 ms-1">49.99</span>
                                    <span className="h6 align-self-end mb-1">/mo</span>
                                </div>
                                <div className="pricing-features text-start bg-light p-3">
                                    <ul className="feature list-unstyled mb-0">
                                        <li className="feature-list text-muted"><FiArrowRightCircle className="fea icon-sm text-dark me-2" />Unlimited Job Posts</li>
                                        <li className="feature-list text-muted mt-2"><FiArrowRightCircle className="fea icon-sm text-dark me-2" />Advanced Search Filters</li>
                                        <li className="feature-list text-muted mt-2"><FiArrowRightCircle className="fea icon-sm text-dark me-2" />Full Access to Candidates</li>
                                        <li className="feature-list text-muted mt-2"><FiArrowRightCircle className="fea icon-sm text-dark me-2" />Unlimited Messaging</li>
                                        <li className="feature-list text-muted mt-2"><FiArrowRightCircle className="fea icon-sm text-dark me-2" />Priority Support</li>
                                    </ul>
                                    <div className="text-center py-3">
                                        <Link to="#" className="btn btn-dark">Get Started</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="pricing text-center rounded position-relative shadow-lg bg-light">
                                <div className="price-header rounded-top bg-primary pt-5 pb-5">
                                    <h5 className="price-title text-white title-dark">Enterprise</h5>
                                    <p className="mb-0 text-white-50">Customized Solutions</p>
                                </div>
                                <div className="d-flex justify-content-center bg-light border-bottom py-5">
                                    <span className="h6 mb-0">$</span>
                                    <span className="price h4 mb-0 ms-1">Contact Us</span>
                                </div>
                                <div className="pricing-features text-start bg-light p-3">
                                    <ul className="feature list-unstyled mb-0">
                                        <li className="feature-list text-muted"><FiArrowRightCircle className="fea icon-sm text-primary me-2" />Custom Search Filters</li>
                                        <li className="feature-list text-muted mt-2"><FiArrowRightCircle className="fea icon-sm text-primary me-2" />Dedicated Account Manager</li>
                                        <li className="feature-list text-muted mt-2"><FiArrowRightCircle className="fea icon-sm text-primary me-2" />Enterprise-Level Security</li>
                                        <li className="feature-list text-muted mt-2"><FiArrowRightCircle className="fea icon-sm text-primary me-2" />24/7 Premium Support</li>
                                    </ul>
                                    <div className="text-center py-3">
                                        <Link to="#" className="btn btn-primary">Contact Sales</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-100 mt-60">
            
                </div>
            </section>
            <Footer />
            <ScrollTop />
        </>
    )
}
