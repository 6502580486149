import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc, addDoc, collection } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import ReactQuill from 'react-quill';
import DatePicker from 'react-datepicker';
import 'react-quill/dist/quill.snow.css';
import 'react-datepicker/dist/react-datepicker.css';
import { db, auth, storage } from '../firebase';
import styled from 'styled-components';
import ExpertNavbar from './expertnavbar';
const Container = styled.div`
  max-width: 900px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background: white;
`;

const Section = styled.div`
  padding: 20px;
  background: white;
  border-bottom: 1px solid #e9ecef;
  &:last-child {
    border-bottom: none;
  }
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  &:first-of-type {
    margin-top: 20px;
  }
`;

const SkillButton = styled(Button)`
  background-color: #f0f0f0;
  color: #333;
  margin-right: 5px;
  margin-bottom: 5px;
`;

const CloseButton = styled.button`
  background-color: red;
  border: none;
  color: white;
  padding: 0 5px;
  line-height: 1.5;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 5px;
  font-size: 12px;
`;

const VideoContainer = styled.div`
  margin-bottom: 10px;
`;

const Video = styled.video`
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
`;

function EditProfile() {
  const navigate = useNavigate();
  const [profileInfo, setProfileInfo] = useState({
    profileImage: '',
    name: '',
    email: '',
    country: '',
    timezone: '',
    gender: '',
    currentJobTitle: '',
    industryCategory: '',
    workExperience: [{ title: '', spanStart: new Date(), spanEnd: new Date(), description: '' }],
    primarySkills: [],
    secondarySkills: [],
    hourlyRate: '',
    cvUrl: '',
    videoIntroUrl: '',
    linkedin: '',
    certifications: [{ title: '', issuer: '', date: new Date() }],
  });

  const [skillsInput, setSkillsInput] = useState('');
  const [cvPreview, setCvPreview] = useState('');
  const [videoPreview, setVideoPreview] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const videoRef = useRef(null);

  const categories = [
    'Marketing', 'Fullstack Engineer', 'Data Science', 'Product Management', 'Graphic Design',
    'UI/UX Design', 'Software Development', 'Project Management', 'Sales', 'Customer Support',
    'Human Resources', 'Finance', 'Legal', 'Education', 'Healthcare',
    'Real Estate', 'Logistics', 'Manufacturing', 'Hospitality', 'Other'
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileInfo(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSkillsChange = (e) => setSkillsInput(e.target.value);

  const addSkill = (skillType) => {
    if (!skillsInput.trim()) return;
    setProfileInfo(prevState => ({
      ...prevState,
      [skillType]: [...prevState[skillType], skillsInput]
    }));
    setSkillsInput('');
  };

  const removeSkill = (skillType, index) => {
    setProfileInfo(prevState => ({
      ...prevState,
      [skillType]: prevState[skillType].filter((_, i) => i !== index)
    }));
  };

  const handleWorkExperienceChange = (index, field, value) => {
    let newWorkExperience = [...profileInfo.workExperience];
    newWorkExperience[index][field] = value;
    setProfileInfo(prevState => ({ ...prevState, workExperience: newWorkExperience }));
  };

  const addWorkExperience = () => {
    setProfileInfo(prevState => ({
      ...prevState,
      workExperience: [...prevState.workExperience, { title: '', spanStart: new Date(), spanEnd: new Date(), description: '' }]
    }));
  };

  const removeWorkExperience = (index) => {
    setProfileInfo(prevState => ({
      ...prevState,
      workExperience: prevState.workExperience.filter((_, i) => i !== index)
    }));
  };

  const handleProfileImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileRef = storageRef(storage, `profileImages/${auth.currentUser.uid}/${file.name}`);
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);
      setProfileInfo(prevState => ({ ...prevState, profileImage: fileUrl }));
    }
  };

  const handleCvUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileRef = storageRef(storage, `cvs/${auth.currentUser.uid}/${file.name}`);
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);
      setCvPreview(fileUrl);
      setProfileInfo(prevState => ({ ...prevState, cvUrl: fileUrl }));
    }
  };

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);
      videoRef.current.srcObject = stream;
      recorder.start();
      setIsRecording(true);

      recorder.ondataavailable = (e) => {
        const videoBlob = new Blob([e.data], { type: 'video/mp4' });
        const videoUrl = URL.createObjectURL(videoBlob);
        setVideoPreview(videoUrl);
        setProfileInfo(prevState => ({ ...prevState, videoIntroUrl: videoUrl }));
      };

      recorder.onstop = () => {
        stream.getTracks().forEach(track => track.stop());
        setIsRecording(false);
      };
    });
  };

  const stopRecording = () => {
    mediaRecorder.stop();
  };

  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileRef = storageRef(storage, `videos/${auth.currentUser.uid}/${file.name}`);
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);
      setVideoPreview(fileUrl);
      setProfileInfo(prevState => ({ ...prevState, videoIntroUrl: fileUrl }));
    }
  };

  const handleCertificationChange = (index, field, value) => {
    let updatedCertifications = [...profileInfo.certifications];
    updatedCertifications[index][field] = value;
    setProfileInfo(prevState => ({ ...prevState, certifications: updatedCertifications }));
  };

  const addCertification = () => {
    setProfileInfo(prevState => ({
      ...prevState,
      certifications: [...prevState.certifications, { title: '', issuer: '', date: new Date() }]
    }));
  };

  const removeCertification = (index) => {
    setProfileInfo(prevState => ({
      ...prevState,
      certifications: prevState.certifications.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setDoc(doc(db, 'profiles', auth.currentUser.uid), profileInfo);
      await addDoc(collection(db, "expertsNotification"), {
        userId: auth.currentUser.uid,
        message: "Your Profile has been updated",
        timestamp: new Date(),
      });
      alert('Profile updated successfully!');
      navigate('/expertdashboard');
    } catch (error) {
      console.error("Error updating profile: ", error);
      alert('Failed to update profile.');
    }
  };

  return (
    <>
<ExpertNavbar />
      <Container>
        <form onSubmit={handleSubmit}>
          <Section>
            <h2 style={{ textAlign: 'center' }}>Create Your Expert Profile</h2>
          </Section>

          <Section>
            <Label>Profile Image:</Label>
            <Input type="file" accept="image/*" onChange={handleProfileImageUpload} />
            {profileInfo.profileImage && <img src={profileInfo.profileImage} alt="Profile Preview" style={{ maxWidth: '200px', marginTop: '10px' }} />}
          </Section>

          <Section>
            <Label>Name:</Label>
            <Input type="text" name="name" value={profileInfo.name} onChange={handleChange} />

            <Label>Email:</Label>
            <Input type="email" name="email" value={profileInfo.email} onChange={handleChange} />

            <Label>Country:</Label>
            <Input type="text" name="country" value={profileInfo.country} onChange={handleChange} />

            <Label>Timezone:</Label>
            <Input type="text" name="timezone" value={profileInfo.timezone} onChange={handleChange} />

            <Label>Gender:</Label>
            <Select name="gender" value={profileInfo.gender} onChange={handleChange}>
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </Select>

            <Label>Current Job Title:</Label>
            <Input type="text" name="currentJobTitle" value={profileInfo.currentJobTitle} onChange={handleChange} />

            <Label>Industry Category:</Label>
            <Select name="industryCategory" value={profileInfo.industryCategory} onChange={handleChange}>
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </Select>
          </Section>

          <Section>
            <Label>Primary Skills:</Label>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Input type="text" placeholder="Add a primary skill" value={skillsInput} onChange={handleSkillsChange} style={{ flex: '1', marginRight: '10px' }} />
              <Button type="button" onClick={() => addSkill('primarySkills')}>Add</Button>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
              {profileInfo.primarySkills.map((skill, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '5px', backgroundColor: '#e9ecef', borderRadius: '20px', padding: '5px 10px' }}>
                  {skill}
                  <Button type="button" onClick={() => removeSkill('primarySkills', index)} style={{ marginLeft: '10px', backgroundColor: 'red' }}>X</Button>
                </div>
              ))}
            </div>
          </Section>

          <Section>
            <Label>Secondary Skills:</Label>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Input type="text" placeholder="Add a secondary skill" value={skillsInput} onChange={handleSkillsChange} style={{ flex: '1', marginRight: '10px' }} />
              <Button type="button" onClick={() => addSkill('secondarySkills')}>Add</Button>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
              {profileInfo.secondarySkills.map((skill, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '5px', backgroundColor: '#e9ecef', borderRadius: '20px', padding: '5px 10px' }}>
                  {skill}
                  <Button type="button" onClick={() => removeSkill('secondarySkills', index)} style={{ marginLeft: '10px', backgroundColor: 'red' }}>X</Button>
                </div>
              ))}
            </div>
          </Section>

          <Section>
            {profileInfo.workExperience.map((experience, index) => (
              <div key={index} style={{ marginBottom: '20px' }}>
                <Label>Role Title:</Label>
                <Input type="text" value={experience.title} onChange={(e) => handleWorkExperienceChange(index, 'title', e.target.value)} />

                <Label>Span of Time:</Label>
                <div style={{ display: 'flex' }}>
                  <DatePicker selected={experience.spanStart} onChange={(date) => handleWorkExperienceChange(index, 'spanStart', date)} style={{ flex: '1', marginRight: '10px' }} />
                  <DatePicker selected={experience.spanEnd} onChange={(date) => handleWorkExperienceChange(index, 'spanEnd', date)} style={{ flex: '1' }} />
                </div>

                <Label>Description:</Label>
                <ReactQuill theme="snow" value={experience.description} onChange={(value) => handleWorkExperienceChange(index, 'description', value)} style={{ height: '150px' }} />
                <Button type="button" onClick={() => removeWorkExperience(index)} style={{ backgroundColor: 'red' , marginTop: '10vh' }}>Remove Role</Button>
              </div>
            ))}
            <Button type="button" onClick={addWorkExperience}>Add Another Role</Button>
          </Section>

          <Section>
            <Label>Certifications:</Label>
            {profileInfo.certifications.map((cert, index) => (
              <div key={index} style={{ marginBottom: '20px' }}>
                <Label>Certification Title:</Label>
                <Input type="text" value={cert.title} onChange={(e) => handleCertificationChange(index, 'title', e.target.value)} />
                <Label>Issuer:</Label>
                <Input type="text" value={cert.issuer} onChange={(e) => handleCertificationChange(index, 'issuer', e.target.value)} />
                <Label>Date:</Label>
                <DatePicker selected={cert.date} onChange={(date) => handleCertificationChange(index, 'date', date)} />
                <Button type="button" onClick={() => removeCertification(index)} style={{ backgroundColor: 'red' }}>Remove Certification</Button>
              </div>
            ))}
            <Button type="button" onClick={addCertification}>Add Another Certification</Button>
          </Section>

          <Section>
            <Label>CV Upload:</Label>
            <Input type="file" onChange={handleCvUpload} />
            {cvPreview && (
              <a href={cvPreview} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: '#007bff' }}>View CV</a>
            )}
          </Section>

          <Section>
            <Label>Video Introduction:</Label>
            <VideoContainer>
              <video ref={videoRef} controls></video>
              {isRecording ? (
                <Button type="button" onClick={stopRecording} style={{ backgroundColor: 'red' }}>Stop Recording</Button>
              ) : (
                <Button type="button" onClick={startRecording}>Start Recording</Button>
              )}
              <Input type="file" accept="video/*" onChange={handleVideoUpload} />
              {videoPreview && <Video src={videoPreview} controls></Video>}
            </VideoContainer>
          </Section>

          <Section>
            <Button type="submit">Update Profile</Button>
          </Section>
        </form>
      </Container>
    </>
  );
}

export default EditProfile;
