import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { FiClock, FiMapPin, FiHome, FiInfo, FiDollarSign, FiCalendar, FiBriefcase, FiFlag, FiLayers, FiUserCheck, FiLink, FiX, FiHeart, FiEye, FiEdit } from 'react-icons/fi';
import styled from 'styled-components';
import Navbar from '../components/navbar';
import FormSelect from '../components/formSelect';
import Footer from '../components/footer';
import AboutTwo from '../components/aboutTwo';
import ScrollTop from '../components/scrollTop';
import ExpertNavbar from './expertnavbar';
import bg1 from '../assets/images/hero/bg.jpg';

// Styled components for layout and visuals
const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 12px;
  width: auto;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 24px;
  color: #333;
  cursor: pointer;

  &:hover {
    color: #000;
  }
`;

const JobCard = styled.div`
  background: white;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const JobTitle = styled.h5`
  font-weight: 600;
  color: #333;
`;

const JobInfo = styled.p`
  color: #6c757d;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 10px 0;

  svg {
    margin-right: 5px;
  }
`;

const JobActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
`;

const JobActionButton = styled.button`
  background: white;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  &:hover {
    background: #007bff;
    color: white;
  }
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export default function JobGridOne() {
    const [jobs, setJobs] = useState([]);
    const [selectedJob, setSelectedJob] = useState(null);
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "jobs"));
                const jobsData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setJobs(jobsData);
            } catch (error) {
                console.error("Error fetching jobs:", error);
            }
        };

        fetchJobs();
    }, []);

    const viewJobDetails = (job) => {
        setSelectedJob(job);
    };

    const closeJobDetails = () => {
        setSelectedJob(null);
    };

    const saveJob = async (jobId) => {
        if (!user) {
            alert("Please log in to save jobs.");
            return;
        }
        const userDocRef = doc(db, "users", user.uid, "savedJobs", jobId);
        try {
            await setDoc(userDocRef, { jobId });
            alert("Job saved successfully.");
        } catch (error) {
            console.error("Error saving job:", error);
            alert("Failed to save job.");
        }
    };

    const applyForJob = (job) => {
        navigate('/job-apply', { state: { jobDetails: job } });
    };

    return (
        <>
            <ExpertNavbar />
            <section style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top', padding: '120px 0', position: 'relative', zIndex: '1', backgroundSize: 'cover' }}>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12 text-center">
                            <h5 style={{ fontWeight: '600', color: 'white' }}>Explore Job Opportunities</h5>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ padding: '60px 0' }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 mt-4">
                            <FormSelect />
                        </div>
                    </div>
                    <div className="container mt-5">
                        <div className="row g-4">
                            {jobs.map((job) => (
                                <div className="col-lg-4 col-md-6 col-12" key={job.id}>
                                    <JobCard>
                                        <div>
                                            <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <img src={job.logo} alt={`${job.companyName} logo`} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                                                <span style={{ background: '#EAEFF3', color: '#333', padding: '5px 10px', borderRadius: '15px' }}>{job.type}</span>
                                            </div>
                                            <JobTitle>{job.title}</JobTitle>
                                            <JobInfo><FiMapPin /> {job.state}, {job.country}</JobInfo>
                                            <JobInfo><FiHome /> {job.companyName}</JobInfo>
                                        </div>
                                        <JobActions>
                                            <JobActionButton onClick={() => viewJobDetails(job)}><FiEye /> View</JobActionButton>
                                            <JobActionButton onClick={() => saveJob(job.id)}><FiHeart /> Save</JobActionButton>
                                            <JobActionButton onClick={() => applyForJob(job)}><FiEdit /> Apply</JobActionButton>
                                        </JobActions>
                                    </JobCard>
                                </div>
                            ))}
                        </div>
                    </div>
                    </div>
            </section>
            {selectedJob && (
                <Modal>
                    <ModalContent>
                        <ModalCloseButton onClick={closeJobDetails}><FiX /></ModalCloseButton>
                        <img src={selectedJob.logo} alt="Company Logo" style={{ width: '100px', height: '100px', margin: '20px auto' }} />
                        <h2>{selectedJob.title} - {selectedJob.companyName}</h2>
                        <JobInfo><FiClock /> Experience: {selectedJob.experience} years</JobInfo>
                        <JobInfo><FiMapPin /> Location: {selectedJob.state}, {selectedJob.country}</JobInfo>
                        <JobInfo><FiBriefcase /> Industry: {selectedJob.industry}</JobInfo>
                        <JobInfo><FiDollarSign /> Salary: ${selectedJob.minSalary} - ${selectedJob.maxSalary} {selectedJob.salaryType}</JobInfo>
                        <JobInfo><FiCalendar /> Expires on: {selectedJob.expiryDate}</JobInfo>
                        <JobInfo><FiUserCheck /> Qualifications: {selectedJob.qualifications}</JobInfo>
                        <JobInfo><FiLayers /> Skills: {selectedJob.skills}</JobInfo>
                        <JobInfo><FiFlag /> Type: {selectedJob.type}</JobInfo>
                        <JobInfo><FiLink /> Website: <a href={selectedJob.website} target="_blank" rel="noopener noreferrer">{selectedJob.website}</a></JobInfo>
                        <p>{selectedJob.description}</p>
                        <ModalActions>
                            <JobActionButton onClick={() => applyForJob(selectedJob)}><FiEdit /> Apply Now</JobActionButton>
                            <JobActionButton onClick={() => saveJob(selectedJob.id)}><FiHeart /> Save Job</JobActionButton>
                        </ModalActions>
                    </ModalContent>
                </Modal>
            )}
            <Footer />
            <ScrollTop />
        </>
    );
}
