import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";

export default function JobDetailOne() {
  const { id } = useParams();
  const [jobData, setJobData] = useState(null);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const docRef = doc(db, "jobs", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setJobData(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching job details: ", error);
      }
    };

    fetchJobDetails();
  }, [id]);

  return (
    <>
      <Navbar navClass="defaultscroll sticky" navLight={true} />

      <section className="bg-half-170 d-table w-100" style={{ backgroundImage: `url(${jobData?.backgroundImage})`, backgroundPosition: 'top' }}>
        <div className="bg-overlay bg-gradient-overlay"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <img src={jobData?.logo} className="avatar avatar-small rounded-pill p-2 bg-white" alt="" />
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark mt-3">{jobData?.title}</h5>
              </div>
            </div>
          </div>

          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                <li className="breadcrumb-item"><Link to="/">Experts Blooms</Link></li>
                <li className="breadcrumb-item"><Link to="/job-grid-one">Jobs</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Job Detail</li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
          </svg>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="card bg-white rounded shadow sticky-bar">
                <div className="p-4">
                  <h5 className="mb-0">Job Information</h5>
                </div>

                <div className="card-body p-4 border-top">
                  <div className="d-flex widget align-items-center">
                    <div className="flex-1">
                      <h6 className="widget-title mb-0">Company Name:</h6>
                      <small className="text-primary mb-0">{jobData?.companyName}</small>
                    </div>
                  </div>

                  <div className="d-flex widget align-items-center mt-3">
                    <div className="flex-1">
                      <h6 className="widget-title mb-0">Type:</h6>
                      <small className="text-primary mb-0">{jobData?.type}</small>
                    </div>
                  </div>

                  <div className="d-flex widget align-items-center mt-3">
                    <div className="flex-1">
                      <h6 className="widget-title mb-0">Location:</h6>
                      <small className="text-primary mb-0">{jobData?.country}, {jobData?.state}, {jobData?.address}</small>
                    </div>
                  </div>

                  <div className="d-flex widget align-items-center mt-3">
                    <div className="flex-1">
                      <h6 className="widget-title mb-0">Experience:</h6>
                      <small className="text-primary mb-0">{jobData?.experience}</small>
                    </div>
                  </div>

                  <div className="d-flex widget align-items-center mt-3">
                    <div className="flex-1">
                      <h6 className="widget-title mb-0">Qualifications:</h6>
                      <small className="text-primary mb-0">{jobData?.qualifications}</small>
                    </div>
                  </div>

                  <div className="d-flex widget align-items-center mt-3">
                    <div className="flex-1">
                      <h6 className="widget-title mb-0">Salary:</h6>
                      <small className="text-primary mb-0">{jobData?.minSalary} - {jobData?.maxSalary} {jobData?.salaryType}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-md-6 col-12">
              <h5>Job Description: </h5>
              <p className="text-muted">{jobData?.description}</p>

              <h5 className="mt-4">Skills Required: </h5>
              <p className="text-muted">{jobData?.skills}</p>
            </div>
          </div>
        </div>
      </section>
      <Footer top={true} />
      <ScrollTop />
    </>
  );
}
