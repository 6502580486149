import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { FiTrash2, FiEdit2 } from 'react-icons/fi';
import EditJobPopup from './editedjobpopup';
import heroImage from '../assets/images/hero/bg2.jpg';
import ClientNavbar from './clientnavbar';

const AllJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        fetchJobs(user.email);
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [auth, navigate]);

  const fetchJobs = async (userEmail) => {
    const q = query(collection(db, 'jobs'), where('userEmail', '==', userEmail));
    const querySnapshot = await getDocs(q);
    const jobList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    setJobs(jobList);
  };

  const handleDeleteJob = async (id) => {
    if (window.confirm('Are you sure you want to delete this job?')) {
      await deleteDoc(doc(db, 'jobs', id));
      setJobs(jobs.filter(job => job.id !== id));
    }
  };

  const handleEditJob = (job) => {
    setSelectedJob(job);
  };

  return (
    <>
      <ClientNavbar />
      <div style={styles.heroSection}>
        <h1 style={styles.heroHeader}>My Job Listings</h1>
        <p style={styles.heroCaption}>Easily manage and track all your job postings</p>
      </div>
      <div style={styles.container}>
        <div style={styles.jobsGrid}>
          {jobs.length > 0 ? jobs.map(job => (
            <div key={job.id} style={styles.jobCard}>
              <img src={job.logo || 'default_logo_placeholder.png'} alt="Company Logo" style={styles.companyLogo} />
              <div style={styles.jobDetails}>
                <h5 style={styles.jobTitle}>{job.title}</h5>
                <p style={styles.jobInfo}>{job.address}</p>
                <p style={styles.jobInfo}>{job.category}</p>
                <p style={styles.jobSalary}>${job.minSalary} - ${job.maxSalary}</p>
              </div>
              <div style={styles.jobCardActions}>
                <button onClick={() => handleEditJob(job)} style={styles.editButton}><FiEdit2 /> Edit</button>
                <button onClick={() => handleDeleteJob(job.id)} style={styles.deleteButton}><FiTrash2 /> Delete</button>
              </div>
            </div>
          )) : <p style={styles.noJobsMessage}>No job postings found. Start adding your jobs!</p>}
        </div>
      </div>
      {selectedJob && (
        <EditJobPopup
          isOpen={Boolean(selectedJob)}
          job={selectedJob}
          onClose={() => setSelectedJob(null)}
          onUpdate={() => fetchJobs(auth.currentUser?.email)}
        />
      )}
    </>
  );
};

const styles = {
  heroSection: {
    backgroundImage: `url(${heroImage})`,
    height: '350px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'rgba(10, 88, 202, 0.7)',
    backgroundBlendMode: 'multiply',
  },
  heroHeader: {
    fontSize: '2.8rem',
    fontWeight: 'bold',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  },
  heroCaption: {
    fontSize: '1.4rem',
    marginTop: '10px',
    textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 20px',
  },
  jobsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
    gap: '20px',
    maxWidth: '1200px',
  },
  jobCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    padding: '20px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  companyLogo: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    marginBottom: '10px',
    alignSelf: 'center',
    objectFit: 'cover',
  },
  jobDetails: {
    padding: '10px 0',
  },
  jobTitle: {
    fontWeight: '600',
    fontSize: '20px',
    color: 'rgb(10, 88, 202)',
  },
  jobInfo: {
    fontSize: '16px',
    color: '#666',
    margin: '5px 0',
  },
  jobSalary: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'rgb(10, 88, 202)',
  },
  jobCardActions: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '15px',
  },
  editButton: {
    backgroundColor: 'rgb(10, 88, 202)',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 15px',
    cursor: 'pointer',
    fontSize: '14px',
  },
  deleteButton: {
    backgroundColor: '#f44336',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 15px',
    cursor: 'pointer',
    fontSize: '14px',
  },
  noJobsMessage: {
    textAlign: 'center',
    fontSize: '20px',
    color: '#666',
    marginTop: '20px',
  },
};

export default AllJobs;
