import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import heroImg from "../assets/images/hero/bg.jpg";
import Navbar from "../components/navbar";
import FormSelect from "../components/formSelect";
import ServicesTwo from "../components/sercicesTwo";
import AboutUs from "../components/aboutUs";
import Companies from "../components/companies";
import Blog from "../components/blog";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import { jobData } from "../data/data";
import ExpertsSlider from "../pages/expertsslider";

import { FiClock, FiMapPin, FiBookmark } from "react-icons/fi";

export default function IndexTwo() {
    const navigate = useNavigate();
    const [jobs, setJobs] = useState([]);
    const db = getFirestore();

    useEffect(() => {
        const fetchJobs = async () => {
            const jobsCollection = collection(db, "jobs");
            const jobSnapshot = await getDocs(jobsCollection);
            const jobList = jobSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setJobs(jobList);
        };
        fetchJobs();
    }, []);

    const applyForJob = (job) => {
        navigate('/expertlogin');
    };

    const saveJob = (jobId) => {
        console.log(`Job ${jobId} saved`);
    };

    return (
        <>
            <Navbar navClass="defaultscroll sticky" navLight={true} />
            <section className="bg-half-260 d-table w-100" style={{ backgroundImage: `url(${heroImg})`, backgroundSize: 'cover', backgroundPosition: 'center', animation: "fadeIn 2s" }}>
                <div className="bg-overlay bg-primary-gradient-overlay"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-lg-10">
                            <div className="title-heading text-center">
                                <h1 className="heading text-white fw-bold">Connect With Industry Leaders</h1>
                                <p className="para-desc text-white-50 mx-auto mb-0">Unlock the door to exceptional career paths. Collaborate with leading organizations committed to pioneering innovation.</p>
                                <div className="d-md-flex justify-content-between align-items-center bg-white shadow rounded p-2 mt-4">
                                    <FormSelect />
                                </div>
                                <div className="mt-2">
                                    <span className="text-white-50">Trending Now: Graphic Design, Software Engineering, Frontend Development, React Native, Full Stack Solutions</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
            <div className="container text-center mt-5" style={{ animation: "zoomIn 3s" }}>
                <h2 className="mb-2">Engage with Global Experts</h2>
                <p>Dive deep into our comprehensive role pool and match your skills with global opportunities. Transform your career trajectory with us!</p>
            </div>
            <ExpertsSlider />
            <section className="section" style={{ paddingTop: '60px' }}>
                <div className="container">
                    <ServicesTwo />
                    <AboutUs containerClass="container mt-100 mt-60" />
                    <div className="container mt-100 mt-60">
                        <div className="row justify-content-center mb-4 pb-2">
                            <div className="col-12">
                                <div className="section-title text-center">
                                    <h4 className="title mb-3">Featured Job Listings</h4>
                                    <p className="text-muted para-desc mx-auto mb-0">Handpicked positions from renowned tech leaders to innovative startups. Where will your skills shine?</p>
                                </div>
                            </div>
                        </div>
                        <div className="row g-4 mt-0">
                            {jobs.map((job) => (
                                <div className="col-12" key={job.id} style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '20px', marginBottom: '20px', display: 'flex', alignItems: 'center', backgroundColor: '#f9f9f9', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', animation: "fadeInUp 2s" }}>
                                    <img src={job.logo || "path/to/default/logo.png"} alt="Company Logo" style={{ width: '60px', height: '60px', marginRight: '20px' }} />
                                    <div style={{ flex: 1, fontSize: '13px' }}>
                                        <h5 className="title text-dark">{job.title}</h5>
                                        <p style={{ margin: '5px 0', color: '#555' }}>{job.experience} years experience</p>
                                        <p style={{ margin: '5px 0', color: '#555' }}>{job.type}</p>
                                        <p style={{ margin: '5px 0', color: '#555' }}>{job.description}</p>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <button style={{ padding: '10px 15px', margin: '0 5px', backgroundColor: '#007bff', color: 'white', border: 'none', borderRadius: '20px', cursor: 'pointer' }} onClick={() => applyForJob(job)}>Apply Now</button>
                                        <button style={{ padding: '10px 15px', margin: '0 5px', backgroundColor: 'white', color: '#007bff', border: '1px solid #007bff', borderRadius: '20px', cursor: 'pointer' }} onClick={() => saveJob(job.id)}><FiBookmark className="icons" /></button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Companies />
                    <Blog />
                </div>
            </section>
            <ScrollTop />
        </>
    );
}
