import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import CustomNavbar from './customnavbar';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import { storage } from '../firebase';
import Select from 'react-select';

// Helper components for different steps of the job posting process
function StepOne({ jobDetails, updateJobDetails, categoriesOptions, handleSelectChange }) {
  return (
    <>
      <div className="form-group">
        <label htmlFor="title">Job Title</label>
        <input type="text" className="form-control" id="title" name="title" value={jobDetails.title} onChange={updateJobDetails} placeholder="Enter job title" />
      </div>
      <div className="form-group">
        <label htmlFor="description">Description</label>
        <textarea className="form-control" id="description" name="description" value={jobDetails.description} onChange={updateJobDetails} rows="3" placeholder="Job description"></textarea>
      </div>
      <div className="form-group">
        <label htmlFor="type">Job Type</label>
        <select className="form-control" id="type" name="type" value={jobDetails.type} onChange={updateJobDetails}>
          <option value="">Select job type</option>
          <option value="Full-Time">Full-Time</option>
          <option value="Part-Time">Part-Time</option>
          <option value="Contract">Contract</option>
          <option value="Internship">Internship</option>
        </select>
      </div>
      <div className="form-group">
        <label>Category</label>
        <Select options={categoriesOptions} onChange={option => handleSelectChange('category', option)} />
      </div>
    </>
  );
}

function StepTwo({ jobDetails, updateJobDetails }) {
  return (
    <>
      <div className="form-group">
        <label htmlFor="salaryType">Salary Type</label>
        <select className="form-control" id="salaryType" name="salaryType" value={jobDetails.salaryType} onChange={updateJobDetails}>
          <option value="">Select salary type</option>
          <option value="Annual">Annual</option>
          <option value="Hourly">Hourly</option>
          <option value="Commission">Commission</option>
          <option value="Project-based">Project-based</option>
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="minSalary">Minimum Salary</label>
        <input type="number" className="form-control" id="minSalary" name="minSalary" value={jobDetails.minSalary} onChange={updateJobDetails} placeholder="Minimum salary" />
      </div>
      <div className="form-group">
        <label htmlFor="maxSalary">Maximum Salary</label>
        <input type="number" className="form-control" id="maxSalary" name="maxSalary" value={jobDetails.maxSalary} onChange={updateJobDetails} placeholder="Maximum salary" />
      </div>
      <div className="form-group">
        <label htmlFor="skills">Skills Required</label>
        <input type="text" className="form-control" id="skills" name="skills" value={jobDetails.skills} onChange={updateJobDetails} placeholder="List essential skills, separated by commas" />
      </div>
    </>
  );
}

function StepThree({ jobDetails, updateJobDetails }) {
  return (
    <>
      <div className="form-group">
        <label htmlFor="qualifications">Qualifications</label>
        <input type="text" className="form-control" id="qualifications" name="qualifications" value={jobDetails.qualifications} onChange={updateJobDetails} placeholder="Required qualifications for the job" />
      </div>
      <div className="form-group">
        <label htmlFor="experience">Experience Required</label>
        <input type="text" className="form-control" id="experience" name="experience" value={jobDetails.experience} onChange={updateJobDetails} placeholder="e.g., 3+ years in software development" />
      </div>
    </>
  );
}

function StepFour({ jobDetails, updateJobDetails, uploadProgress, setUploadProgress }) {
  const updateLogo = (event) => {
    const file = event.target.files[0];
    const fileRef = ref(storage, `logos/${Date.now()}_${file.name}`);
    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on('state_changed',
      snapshot => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      error => {
        console.error('Upload failed:', error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        updateJobDetails({ target: { name: 'logo', value: downloadURL } });
      }
    );
  };

  return (
    <>
      <div className="form-group">
        <label htmlFor="industry">Industry</label>
        <input type="text" className="form-control" id="industry" name="industry" value={jobDetails.industry} onChange={updateJobDetails} placeholder="e.g., Financial Services, Technology" />
      </div>
      <div className="form-group">
        <label htmlFor="address">Address</label>
        <input type="text" className="form-control" id="address" name="address" value={jobDetails.address} onChange={updateJobDetails} placeholder="Full address of the job location" />
      </div>
      <div className="form-group">
        <label htmlFor="country">Country</label>
        <input type="text" className="form-control" id="country" name="country" value={jobDetails.country} onChange={updateJobDetails} placeholder="Country" />
      </div>
      <div className="form-group">
        <label htmlFor="state">State/Region</label>
        <input type="text" className="form-control" id="state" name="state" value={jobDetails.state} onChange={updateJobDetails} placeholder="State or Region" />
      </div>
      <div className="form-group">
        <label htmlFor="logo">Company Logo</label>
        <input type="file" className="form-control-file" id="logo" name="logo" onChange={updateLogo} accept="image/*" />
        {uploadProgress > 0 && (
          <div className="progress mt-2">
            <div className="progress-bar" role="progressbar" style={{ width: `${uploadProgress}%` }} aria-valuenow={uploadProgress} aria-valuemin="0" aria-valuemax="100">
              {uploadProgress}%
            </div>
          </div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="expiryDate">Job Expiry Date</label>
        <input type="date" className="form-control" id="expiryDate" name="expiryDate" value={jobDetails.expiryDate} onChange={updateJobDetails} />
      </div>
      <div className="form-group">
        <label htmlFor="companyName">Company Name</label>
        <input type="text" className="form-control" id="companyName" name="companyName" value={jobDetails.companyName} onChange={updateJobDetails} placeholder="Enter company name" />
      </div>
      <div className="form-group">
        <label htmlFor="website">Company Website</label>
        <input type="url" className="form-control" id="website" name="website" value={jobDetails.website} onChange={updateJobDetails} placeholder="Enter company website" />
      </div>
    </>
  );
}

function StepFive({ jobDetails, setJobDetails }) {
  // This function handles changes to the individual questions
  const handleQuestionChange = (index, event) => {
    const newQuestions = [...jobDetails.questions];
    newQuestions[index] = event.target.value;
    setJobDetails({ ...jobDetails, questions: newQuestions });
  };

  // Check if the questions array is defined and has elements
  if (!Array.isArray(jobDetails.questions) || jobDetails.questions.length === 0) {
    // Initialize questions if not already defined
    setJobDetails(prevDetails => ({
      ...prevDetails,
      questions: ['', '', ''] // Default to three empty questions if none are present
    }));
    return <div>Loading questions... Please wait.</div>;
  }

  return (
    <>
      {jobDetails.questions.map((question, index) => (
        <div key={index} className="form-group">
          <label htmlFor={`question-${index}`}>Question {index + 1}</label>
          <input
            type="text"
            className="form-control"
            id={`question-${index}`}
            name={`question-${index}`}
            value={question}
            onChange={(e) => handleQuestionChange(index, e)}
            placeholder={`Question ${index + 1}`}
          />
        </div>
      ))}
    </>
  );
}

export default function JobPost() {
  const navigate = useNavigate();
  const db = getFirestore();
  const auth = getAuth();
  const [currentUser, setCurrentUser] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [jobDetails, setJobDetails] = useState({
    title: '',
    description: '',
    type: '',
    category: '',
    salaryType: '',
    minSalary: '',
    maxSalary: '',
    skills: '',
    qualifications: '',
    experience: '',
    industry: '',
    address: '',
    country: '',
    state: '',
    logo: '',
    questions: ['', '', ''],
    expiryDate: '',
    companyName: '',
    website: '',
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [submissionMessage, setSubmissionMessage] = useState('');

  // Define the steps of the form here to be used throughout the component
  const steps = [
    'Job Details',
    'Salary & Skills',
    'Qualifications & Experience',
    'Location, Logo, & Company Info',
    'Questions',
  ];

  const categoriesOptions = [
    { value: 'Marketing', label: 'Marketing' },
    { value: 'Fullstack Engineer', label: 'Fullstack Engineer' },
    { value: 'Data Science', label: 'Data Science' },
    { value: 'Product Management', label: 'Product Management' },
    { value: 'Graphic Design', label: 'Graphic Design' },
    { value: 'UI/UX Design', label: 'UI/UX Design' },
    { value: 'Software Development', label: 'Software Development' },
    { value: 'Project Management', label: 'Project Management' },
    { value: 'Sales', label: 'Sales' },
    { value: 'Customer Support', label: 'Customer Support' },
    { value: 'Human Resources', label: 'Human Resources' },
    { value: 'Finance', label: 'Finance' },
    { value: 'Legal', label: 'Legal' },
    { value: 'Education', label: 'Education' },
    { value: 'Healthcare', label: 'Healthcare' },
    { value: 'Real Estate', label: 'Real Estate' },
    { value: 'Logistics', label: 'Logistics' },
    { value: 'Manufacturing', label: 'Manufacturing' },
    { value: 'Hospitality', label: 'Hospitality' },
    { value: 'Other', label: 'Other' },
  ].map(category => ({ value: category.value, label: category.label }));

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        setCurrentUser(user);
      } else {
        navigate("/login");
      }
    });
  }, [auth, navigate]);

  const updateJobDetails = (e) => {
    const { name, value } = e.target;
    setJobDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentUser) {
      try {
        await addDoc(collection(db, 'jobs'), { ...jobDetails, userEmail: currentUser.email });
        setSubmissionMessage('Job posted successfully.');
        navigate("/clientdashboard");
      } catch (error) {
        console.error("Error posting job:", error);
        setSubmissionMessage('An error occurred during submission. Please try again.');
      }
    }
  };

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="job-post-container">
      <CustomNavbar />
      <div className="container my-5">
        <form onSubmit={handleSubmit}>
          <div className="steps-header mb-5">
            <h2 className="text-center">Post a New Job</h2>
            <div className="progress">
              <div className="progress-bar" style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }} aria-valuemin="0" aria-valuemax="100">
                {Math.round((currentStep / (steps.length - 1)) * 100)}%
              </div>
            </div>
          </div>

          {currentStep === 0 && <StepOne jobDetails={jobDetails} updateJobDetails={updateJobDetails} categoriesOptions={categoriesOptions} handleSelectChange={setJobDetails} />}
          {currentStep === 1 && <StepTwo jobDetails={jobDetails} updateJobDetails={updateJobDetails} />}
          {currentStep === 2 && <StepThree jobDetails={jobDetails} updateJobDetails={updateJobDetails} />}
          {currentStep === 3 && <StepFour jobDetails={jobDetails} updateJobDetails={updateJobDetails} uploadProgress={uploadProgress} setUploadProgress={setUploadProgress} />}
          {currentStep === 4 && <StepFive jobDetails={jobDetails} setJobDetails={setJobDetails} />}

          {submissionMessage && (
            <div className="alert alert-success mt-4" role="alert">
              {submissionMessage}
            </div>
          )}

          <div className="form-navigation d-flex justify-content-between mt-4">
            <button type="button" className="btn btn-secondary" onClick={handleBack} disabled={currentStep === 0}>Back</button>
            <button type="button" className="btn btn-primary" onClick={handleNext} disabled={currentStep === steps.length - 1}>Next</button>
            {currentStep === steps.length - 1 && (
              <button type="submit" className="btn btn-success">Submit</button>
            )}
          </div>
        </form>
      </div>
      <Footer />
      <ScrollTop />
    </div>
  );
}
