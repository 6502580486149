import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBriefcase, faPlusCircle, faUsers, faTags } from '@fortawesome/free-solid-svg-icons';
import { db, auth } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

const ClientSidebar = () => {
  const [jobsCount, setJobsCount] = useState(0);
  const [applicantsCount, setApplicantsCount] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    const fetchCounts = async (user) => {
      if (!user) return;

      // Fetching jobs posted by the logged-in user
      const jobsQuery = query(collection(db, 'jobs'), where('userEmail', '==', user.email));
      const jobsSnapshot = await getDocs(jobsQuery);
      const jobIds = jobsSnapshot.docs.map(doc => doc.id);
      setJobsCount(jobsSnapshot.size);

      // Fetching applications for jobs posted by the logged-in user
      if (jobIds.length > 0) {
        const applicationsQuery = query(collection(db, 'jobApplications'), where('jobID', 'in', jobIds));
        const applicationsSnapshot = await getDocs(applicationsQuery);
        setApplicantsCount(applicationsSnapshot.size);
      } else {
        setApplicantsCount(0);
      }
    };

    const unsubscribeAuth = onAuthStateChanged(auth, user => {
      if (user) {
        fetchCounts(user);
      }
    });

    return () => {
      window.removeEventListener('resize', handleResize);
      unsubscribeAuth();
    };
  }, []);

  const isMobile = windowWidth <= 768;

  const sidebarStyle = {
    top: isMobile ? '0' : '60px',
    left: isSidebarOpen || !isMobile ? 0 : '-260px',
    width: '250px',
    height: isMobile ? '100%' : 'calc(100% - 60px)',
    backgroundColor: '#fff',
    boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
    padding: '20px 10px',
    transition: 'left 0.3s ease-in-out',
    zIndex: 1050,
  };

  const linkStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    margin: '5px 0',
    textDecoration: 'none',
    color: 'black',
    borderRadius: '8px',
    transition: 'background-color 0.2s, transform 0.2s',
    backgroundColor: '#f8f9fa',
  };

  const activeLinkStyle = {
    backgroundColor: '#e7f1ff',
    transform: 'translateX(5px)',
  };

  const iconStyle = {
    marginRight: '15px',
  };

  

  return (
    <>
    

      <div style={sidebarStyle}>
        <NavLink to="/alljobs" style={linkStyle} activeStyle={activeLinkStyle}>
          <FontAwesomeIcon icon={faBriefcase} style={iconStyle} /> Manage Jobs <span>({jobsCount})</span>
        </NavLink>
        <NavLink to="/applicants" style={linkStyle} activeStyle={activeLinkStyle}>
          <FontAwesomeIcon icon={faUsers} style={iconStyle} /> Applicants <span>({applicantsCount})</span>
        </NavLink>
        <NavLink to="/job-post" style={linkStyle} activeStyle={activeLinkStyle}>
          <FontAwesomeIcon icon={faPlusCircle} style={iconStyle} /> Create a Job
        </NavLink>
        <NavLink to="/pricing" style={linkStyle} activeStyle={activeLinkStyle}>
          <FontAwesomeIcon icon={faTags} style={iconStyle} /> Pricing
        </NavLink>
      </div>
    </>
  );
};

export default ClientSidebar;
