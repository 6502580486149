import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { collection, query, where, getDocs, writeBatch, documentId } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import ExpertNavbar from './expertnavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faBriefcase, faEye } from '@fortawesome/free-solid-svg-icons';
import { onSnapshot } from 'firebase/firestore';
function SavedJobs() {
    const [savedJobs, setSavedJobs] = useState([]);
    const navigate = useNavigate();
    const [user] = useAuthState(auth);

    useEffect(() => {
        if (!user) {
            setSavedJobs([]);
            return;
        }
        
        const savedJobsRef = collection(db, "users", user.uid, "savedJobs");
        const unsubscribe = onSnapshot(savedJobsRef, async (querySnapshot) => {
            const jobIds = querySnapshot.docs.map(doc => doc.id);
            
            if (jobIds.length > 0) {
                const jobsQuery = query(collection(db, "jobs"), where(documentId(), 'in', jobIds));
                const jobsSnapshot = await getDocs(jobsQuery);
                setSavedJobs(jobsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            } else {
                setSavedJobs([]);
            }
        }, (error) => {
            console.error("Error fetching saved jobs:", error);
        });

        return () => unsubscribe();
    }, [user]);

    const clearSavedJobs = async () => {
        if (!user) {
            alert('You need to be logged in to clear saved jobs.');
            return;
        }

        try {
            const savedJobsRef = collection(db, "users", user.uid, "savedJobs");
            const snapshot = await getDocs(savedJobsRef);
            const batch = writeBatch(db);
            
            snapshot.docs.forEach(doc => {
                batch.delete(doc.ref);
            });

            await batch.commit();
            setSavedJobs([]);
            alert('All saved jobs have been cleared.');
        } catch (error) {
            console.error('Error clearing saved jobs:', error);
            alert('Failed to clear saved jobs.');
        }
    };

    return (
        <>
            <ExpertNavbar />
            <div style={styles.container}>
                <h2 style={styles.header}>Saved Jobs</h2>
                <button onClick={clearSavedJobs} style={styles.clearButton}>
                    Clear Saved Jobs
                </button>
                {savedJobs.length > 0 ? (
                    savedJobs.map(job => (
                        <div key={job.id} style={styles.jobCard}>
                            <img src={job.logo || 'https://via.placeholder.com/60'} alt="Company Logo" style={styles.jobImage} />
                            <div style={styles.jobDetails}>
                                <h3 style={styles.jobTitle}>{job.title}</h3>
                                <p style={styles.jobInfo}><FontAwesomeIcon icon={faClock} /> {job.experience} years</p>
                                <p style={styles.jobInfo}><FontAwesomeIcon icon={faBriefcase} /> {job.type}</p>
                                <p style={styles.jobDescription}>{job.description}</p>
                            </div>
                            <div style={styles.actionButtons}>
                                <button style={{ ...styles.button, ...styles.applyButton }} onClick={() => navigate(`/job-apply/${job.id}`)}>Apply Now</button>
                                <button style={{ ...styles.button, ...styles.viewButton }} onClick={() => navigate(`/jobs/${job.id}`)}><FontAwesomeIcon icon={faEye} /> View</button>
                            </div>
                        </div>
                    ))
                ) : (
                    <p style={styles.noJobsMessage}>No saved jobs found.</p>
                )}
            </div>
        </>
    );
}

const styles = {
    container: {
        maxWidth: '800px',
        margin: '40px auto',
        padding: '20px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px'
    },
    header: {
        textAlign: 'center',
        marginBottom: '20px'
    },
    clearButton: {
        padding: '10px 15px',
        backgroundColor: '#dc3545',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginBottom: '20px',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    jobCard: {
        border: '1px solid #ddd',
        borderRadius: '5px',
        padding: '20px',
        marginBottom: '20px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
    },
    jobImage: {
        width: '60px',
        height: '60px',
        marginRight: '20px'
    },
    jobDetails: {
        flex: 1,
        fontSize: '14px'
    },
    jobTitle: {
        margin: 0,
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#333'
    },
    jobInfo: {
        margin: '5px 0',
        color: '#555'
    },
    jobDescription: {
        margin: '5px 0',
        color: '#555'
    },
    actionButtons: {
        display: 'flex',
        flexDirection: 'column'
    },
    button: {
        padding: '10px 20px',
        margin: '0 5px',
        borderRadius: '5px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '14px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
    },
    applyButton: {
        backgroundColor: '#007bff',
        color: 'white'
    },
    viewButton: {
        backgroundColor: '#28a745',
        color: 'white',
        marginTop: '10px'
    },
    noJobsMessage: {
        textAlign: 'center'
    }
};

export default SavedJobs;
