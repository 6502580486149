import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Webcam from 'react-webcam';
import { db, storage } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ClientNavbar from './clientnavbar';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import bg1 from '../assets/images/hero/bg.jpg';
import logo1 from '../assets/images/logo-dark.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faGlobe, faCalendarAlt, faClock, faBriefcase, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default function JobApply() {
    const { state } = useLocation();
    const jobDetails = state?.jobDetails || {};

    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        jobType: jobDetails.type || '',
        coverLetter: '',
        termsAccepted: false,
    });
    const [recordedChunks, setRecordedChunks] = useState([]);
    const webcamRef = useRef(null);
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const [recordedVideo, setRecordedVideo] = useState(null);

    useEffect(() => {
        return () => {
            if (webcamRef.current && webcamRef.current.srcObject) {
                const tracks = webcamRef.current.srcObject.getTracks();
                tracks.forEach(track => track.stop());
            }
        };
    }, []);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
    };

    const handleNextStep = () => setStep(prevStep => Math.min(prevStep + 1, 4));
    const handlePreviousStep = () => setStep(prevStep => Math.max(prevStep - 1, 1));

    const handleStartRecording = () => {
        setIsRecording(true);
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then(stream => {
                webcamRef.current.srcObject = stream;
                mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'video/webm' });
                mediaRecorderRef.current.addEventListener('dataavailable', event => {
                    if (event.data.size > 0) {
                        setRecordedChunks(prev => [...prev, event.data]);
                    }
                });
                mediaRecorderRef.current.start();
            })
            .catch(err => console.error("Error starting recording: ", err));
    };

    const handleStopRecording = () => {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
        const videoBlob = new Blob(recordedChunks, { type: 'video/webm' });
        setRecordedVideo(URL.createObjectURL(videoBlob));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let videoURL = '';
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, { type: 'video/webm' });
            const videoRef = ref(storage, `jobApplications/${Date.now()}_video.webm`);
            await uploadBytes(videoRef, blob).then(async snapshot => {
                videoURL = await getDownloadURL(snapshot.ref);
            });
        }

        const applicationData = {
            ...formData,
            jobID: jobDetails.id,
            videoURL,
        };

        try {
            await addDoc(collection(db, 'jobApplications'), applicationData);
            await addDoc(collection(db, 'expertsNotification'), {
                message: `You have Applied to ${jobDetails.title} Job`,
                email: formData.email,
                timestamp: new Date(),
                jobLogo: jobDetails.logo
            });
            alert('Application submitted successfully!');
            setStep(1); // Reset to the first step for a new application
        } catch (error) {
            console.error('Error submitting application:', error);
            alert('Failed to submit application.');
        }
    };

    return (
        <>
            <ClientNavbar />
            <section style={{ backgroundImage: `url(${bg1})`, backgroundSize: 'cover', backgroundPosition: 'center', padding: '120px 0' }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <img src={jobDetails.logo || logo1} alt="company logo" style={{ width: '120px', margin: '0 auto', display: 'block', padding: '15px', background: 'white', borderRadius: '50%' }} />
                            <h1 style={{ color: 'white', marginTop: '20px' }}>Job Application: {jobDetails?.title}</h1>
                            <div style={{ color: 'rgba(255, 255, 255, 0.8)', marginTop: '10px', fontSize: '16px' }}>
                                <p><FontAwesomeIcon icon={faBuilding} /> {jobDetails?.companyName}</p>
                                <p><FontAwesomeIcon icon={faGlobe} /> {jobDetails?.country}</p>
                                <p><FontAwesomeIcon icon={faBriefcase} /> {jobDetails?.experience} years experience</p>
                                <p><FontAwesomeIcon icon={faCalendarAlt} /> Expires on: {jobDetails?.expiryDate}</p>
                                <p><FontAwesomeIcon icon={faInfoCircle} /> {jobDetails?.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container" style={{ marginTop: '30px', padding: '20px', backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        {step === 1 && (
                            <>
                                <div className="col-md-6" style={{ marginBottom: '20px' }}>
                                    <label htmlFor="name" className="form-label" style={{ fontWeight: 'bold' }}>Your Name:</label>
                                    <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleInputChange} required />
                                </div>
                                <div className="col-md-6" style={{ marginBottom: '20px' }}>
                                    <label htmlFor="email" className="form-label" style={{ fontWeight: 'bold' }}>Your Email:</label>
                                    <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleInputChange} required />
                                </div>
                                <div className="col-md-6" style={{ marginBottom: '20px' }}>
                                    <label htmlFor="phone" className="form-label" style={{ fontWeight: 'bold' }}>Your Phone No.:</label>
                                    <input type="tel" className="form-control" id="phone" name="phone" value={formData.phone} onChange={handleInputChange} required />
                                </div>
                            </>
                        )}
                        {step === 2 && (
                            <div className="col-12" style={{ marginBottom: '20px' }}>
                                <label htmlFor="coverLetter" className="form-label" style={{ fontWeight: 'bold' }}>Cover Letter:</label>
                                <textarea className="form-control" id="coverLetter" name="coverLetter" rows="5" value={formData.coverLetter} onChange={handleInputChange} required></textarea>
                            </div>
                        )}
                        {step === 3 && (
                            <>
                                <div className="col-12" style={{ marginBottom: '20px' }}>
                                    <p className="mb-3" style={{ fontWeight: 'bold' }}>Please answer the following questions in your video:</p>
                                    <div style={{ padding: '15px', border: '2px solid #007bff', borderRadius: '10px', marginBottom: '20px', backgroundColor: '#e9ecef' }}>
                                        {jobDetails?.questions && jobDetails.questions.length > 0 ? (
                                            <ol>
                                                {jobDetails.questions.map((question, index) => (
                                                    <li key={index} style={{ marginBottom: '10px', fontWeight: '500' }}>{question}</li>
                                                ))}
                                            </ol>
                                        ) : <p>No specific questions for this job application.</p>}
                                    </div>
                                    <div>
                                        <Webcam audio={true} ref={webcamRef} style={{ width: '100%', borderRadius: '8px' }} />
                                        {isRecording ? (
                                            <button type="button" onClick={handleStopRecording} className="btn btn-danger mt-3">Stop Recording</button>
                                        ) : (
                                            <button type="button" onClick={handleStartRecording} className="btn btn-primary mt-3">Start Recording</button>
                                        )}
                                        {recordedVideo && <video src={recordedVideo} controls style={{ marginTop: '10px', maxWidth: '100%', borderRadius: '8px' }} />}
                                    </div>
                                </div>
                            </>
                        )}
                        {step === 4 && (
                            <div className="col-12" style={{ marginBottom: '20px' }}>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="termsAccepted" name="termsAccepted" checked={formData.termsAccepted} onChange={handleInputChange} required />
                                    <label className="form-check-label" htmlFor="termsAccepted">I accept the <Link to="#">Terms and Conditions</Link></label>
                                </div>
                            </div>
                        )}
                        <div className="col-12 text-center">
                            {step > 1 && (
                                <button type="button" onClick={handlePreviousStep} className="btn btn-secondary">Previous</button>
                            )}
                            {step < 4 ? (
                                <button type="button" onClick={handleNextStep} className="btn btn-primary">Next</button>
                            ) : (
                                <button type="submit" className="btn btn-success">Apply Now</button>
                            )}
                        </div>
                    </div>
                </form>
                <div className="progress" style={{ marginTop: '30px' }}>
                    <div className="progress-bar" role="progressbar" style={{ width: `${(step / 4) * 100}%`, backgroundColor: '#007bff', borderRadius: '5px' }} aria-valuenow={(step / 4) * 100} aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <Footer />
            <ScrollTop />
        </>
    );
}
