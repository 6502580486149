import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'shards-react';
import { FiX } from 'react-icons/fi';

const EditJob = ({ isOpen, onClose, job }) => {
    const [updatedJobDetails, setUpdatedJobDetails] = useState({ ...job });

    const handleInputChange = (e, field) => {
        setUpdatedJobDetails(prevState => ({
            ...prevState,
            [field]: e.target.value
        }));
    };

    const handleUpdateJob = async () => {
        try {
            const jobRef = doc(db, 'jobs', job.id);
            await updateDoc(jobRef, updatedJobDetails);
            onClose(); // Close the edit job window
        } catch (error) {
            console.error('Error updating job:', error);
        }
    };

    return (
        <Modal open={isOpen} toggle={onClose}>
            <ModalHeader>Edit Job</ModalHeader>
            <ModalBody>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group">
                        <label htmlFor="title">Title</label>
                        <input type="text" id="title" className="form-control" value={updatedJobDetails.title} onChange={(e) => handleInputChange(e, 'title')} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <input type="text" id="address" className="form-control" value={updatedJobDetails.address} onChange={(e) => handleInputChange(e, 'address')} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="category">Category</label>
                        <input type="text" id="category" className="form-control" value={updatedJobDetails.category} onChange={(e) => handleInputChange(e, 'category')} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="country">Country</label>
                        <input type="text" id="country" className="form-control" value={updatedJobDetails.country} onChange={(e) => handleInputChange(e, 'country')} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <input type="text" id="description" className="form-control" value={updatedJobDetails.description} onChange={(e) => handleInputChange(e, 'description')} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="experience">Experience</label>
                        <input type="text" id="experience" className="form-control" value={updatedJobDetails.experience} onChange={(e) => handleInputChange(e, 'experience')} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="industry">Industry</label>
                        <input type="text" id="industry" className="form-control" value={updatedJobDetails.industry} onChange={(e) => handleInputChange(e, 'industry')} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="logo">Logo</label>
                        <input type="text" id="logo" className="form-control" value={updatedJobDetails.logo} onChange={(e) => handleInputChange(e, 'logo')} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="maxSalary">Max Salary</label>
                        <input type="text" id="maxSalary" className="form-control" value={updatedJobDetails.maxSalary} onChange={(e) => handleInputChange(e, 'maxSalary')} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="minSalary">Min Salary</label>
                        <input type="text" id="minSalary" className="form-control" value={updatedJobDetails.minSalary} onChange={(e) => handleInputChange(e, 'minSalary')} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="qualifications">Qualifications</label>
                        <input type="text" id="qualifications" className="form-control" value={updatedJobDetails.qualifications} onChange={(e) => handleInputChange(e, 'qualifications')} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="salaryType">Salary Type</label>
                        <input type="text" id="salaryType" className="form-control" value={updatedJobDetails.salaryType} onChange={(e) => handleInputChange(e, 'salaryType')} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="skills">Skills</label>
                        <input type="text" id="skills" className="form-control" value={updatedJobDetails.skills} onChange={(e) => handleInputChange(e, 'skills')} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="state">State</label>
                        <input type="text" id="state" className="form-control" value={updatedJobDetails.state} onChange={(e) => handleInputChange(e, 'state')} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="type">Type</label>
                        <input type="text" id="type" className="form-control" value={updatedJobDetails.type} onChange={(e) => handleInputChange(e, 'type')} />
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button theme="primary" onClick={handleUpdateJob}>Update Job</Button>
                <Button theme="secondary" onClick={onClose}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default EditJob;
